import { ActionTree, MutationTree, GetterTree } from 'vuex'
import {
    INPSStatsSummary,
    INPSSummary,
    INPSTrendGraph,
    IThemes,
    IThemeSetting,
    SentimentTrendGraph,
    SentimentTrendGraphCollection,
    Nav,
    getQuestionTypeOptions,
} from '@/entities'
import {
    getNPSStatsSummary,
    getNPSSummary,
    getNPSTrendGraph,
    getSentimentTrendGraph,
} from '@/api/nps'
import { getLeaderboard } from '@/api/leaderboard'
import { getTheme } from '@/api/theme'
import { getPivotTable } from '@/api/pivotTable'
import { getNavMenu, getQuestions } from '@/api/dash'
import { ConvUIQuestions } from '@/pages/dashboard/entities/dash'
import { PivotTable } from '@/pages/dashboard/entities/pivotTable'
import { sleep } from '@/utils/async'
import { getResponses } from '@/api/responses'
import { OFFLINE_RESPONSE_COUNT } from '@/store/modules/scorecard'
import { toggleResponseStarred } from '@/api/scorecard'

export interface DashboardState {
    npsSummary: INPSSummary
    npsStatsSummary: INPSStatsSummary
    npsTrendGraph: INPSTrendGraph
    sentimentTrendGraph: SentimentTrendGraph
    themesMap: { [key: string]: IThemes }
    themeSettings: IThemeSetting[]
    summaryCurrentLeaderboard: string
    leaderboards: {} // name to value pairs, so we can cache some results
    leaderboardLoading: boolean
    freeTextTrendGraphs: SentimentTrendGraphCollection
    responsesMap: {} // key to responses map, json string of filter as key,
    loadingConvQuestions: boolean
    convQuestions: ConvUIQuestions // conv questions
    pivotTables: { [key: string]: PivotTable }
    navMenu: Nav
    canViewFrontlineManagerDashboard: boolean
}

export const state: DashboardState = {
    npsSummary: {
        nps: 0.0,
        change: 0.0,
        total: 0,
    },
    npsStatsSummary: {
        total: 0,
        promoter: 0,
        passive: 0,
        detractor: 0,
        sent: 0,
        opened: 0,
        responded: 0,
        responseRate: 0.0,
    },
    npsTrendGraph: {
        labels: [],
        hoverDate: [],
        nps: [],
        totalResponded: [],
        validMonths: 0,
        trendGraphLabel: '',
        period: '',
        isNoData: true,
    },
    sentimentTrendGraph: {
        labels: [],
        datasets: {
            negative: [],
            neutral: [],
            positive: [],
        },
    },
    themesMap: {},
    themeSettings: [],
    summaryCurrentLeaderboard: '', // which leaderboard to show in summary tab
    leaderboards: {},
    leaderboardLoading: false,
    freeTextTrendGraphs: {},
    responsesMap: {},
    loadingConvQuestions: false,
    convQuestions: {},
    pivotTables: {},
    navMenu: {
        title: '',
        logo: '',
        items: [],
    },
    canViewFrontlineManagerDashboard: false,
}

const getters: GetterTree<DashboardState, any> = {
    npsSummary({ npsSummary }) {
        return npsSummary
    },

    npsStatsSummary({ npsStatsSummary }) {
        return npsStatsSummary
    },

    npsTrendGraph({ npsTrendGraph }) {
        return npsTrendGraph
    },

    sentimentTrendGraph({ sentimentTrendGraph }) {
        return sentimentTrendGraph
    },

    themesMap({ themesMap }) {
        return themesMap
    },

    themeSettings({ themeSettings }) {
        return themeSettings
    },

    leaderboards({ leaderboards }) {
        return leaderboards
    },

    leaderboardLoading({ leaderboardLoading }) {
        return leaderboardLoading
    },

    summaryCurrentLeaderboard({ summaryCurrentLeaderboard }) {
        return summaryCurrentLeaderboard
    },

    freeTextTrendGraphs({ freeTextTrendGraphs }) {
        return freeTextTrendGraphs
    },

    responsesMap({ responsesMap }) {
        return responsesMap
    },

    convQuestions({ convQuestions }) {
        return convQuestions
    },

    pivotTables({ pivotTables }) {
        return pivotTables
    },

    navMenu({ navMenu }) {
        return navMenu
    },

    canViewFrontlineManagerDashboard({ canViewFrontlineManagerDashboard }) {
        return canViewFrontlineManagerDashboard
    },
}

const actions: ActionTree<DashboardState, any> = {
    async updateNpsSummary({ commit, rootGetters }, questionType = '') {
        if (rootGetters.runsInBackground) {
            return
        }

        const timeUnit = rootGetters.hasNetworkConnection ? '' : 'month'
        const timeValue = rootGetters.hasNetworkConnection ? 0 : 1
        const { data } = await getNPSSummary(
            0,
            timeUnit,
            timeValue,
            undefined,
            questionType
        )
        commit('setNpsSummary', { npsSummary: data })
    },

    async updateNpsStatsSummary({ commit, rootGetters }, questionType = '') {
        if (rootGetters.runsInBackground) {
            return
        }

        const timeUnit = rootGetters.hasNetworkConnection ? '' : 'month'
        const timeValue = rootGetters.hasNetworkConnection ? 0 : 1
        const { data } = await getNPSStatsSummary(
            questionType,
            timeUnit,
            timeValue
        )
        commit('setNpsStatsSummary', { npsStatsSummary: data })
    },

    async updateNpsTrendGraph({ commit, rootGetters }, questionType = '') {
        if (rootGetters.runsInBackground) {
            return
        }

        const { data } = await getNPSTrendGraph(questionType)
        commit('setNpsTrendGraph', { npsTrendGraph: data })
    },

    async loadLeaderboard(
        { commit },
        payload: { leaderboard: string; questionType?: string }
    ) {
        const leaderboardName = payload.leaderboard
        const questionType = payload.questionType ?? ''
        const { data } = await getLeaderboard(leaderboardName, questionType)
        commit('setLeaderboard', { leaderboardName, data })
    },

    async loadSavedLeaderboards(
        { commit, dispatch, state },
        payload: { leaderboards: [string]; questionType?: string }
    ) {
        if (!state.leaderboardLoading) {
            commit('setLeaderboardLoading', true)

            const questionType = payload.questionType ?? ''
            for (const name of payload.leaderboards) {
                await dispatch('loadLeaderboard', {
                    leaderboard: name,
                    questionType,
                })
            }

            commit('setLeaderboardLoading', false)
        }
    },

    setResponses({ commit }, { key, data }) {
        commit('setResponses', { key, data })
    },

    async loadSentimentTrendGraph({ commit }, column) {
        const { data } = await getSentimentTrendGraph(column)
        commit('setSentimentTrendGraph', { column, data })
    },

    setSummaryCurrentLeaderboard({ commit }, summaryCurrentLeaderboard) {
        commit('setSummaryCurrentLeaderboard', {
            summaryCurrentLeaderboard,
        })
    },

    setThemeSettings({ commit }, themeSettings) {
        commit('setThemeSettings', { themeSettings })
    },

    setThemes({ commit }, { key, themes }) {
        commit('setThemes', { key, themes })
    },

    async loadTheme(
        { commit, state, dispatch },
        { key, themeId, includeTotal }
    ) {
        const { data } = await getTheme(themeId, includeTotal)
        commit('setTheme', { key, themeId, data, includeTotal })

        // when theme updated/created, if it's a multi value theme, we will need to index the questionparam firstly,
        // meanwhile, let's show that we are collecting data and try refresh every 5 seconds
        if (data.indexing) {
            // try again seconds later
            await sleep(5000)
            dispatch('loadTheme', { key, themeId, includeTotal })
        }
    },

    setCSat({ commit }, csat) {
        commit('setCSat', { csat })
    },

    setConvQuestion({ commit }, { param, questionDetail }) {
        commit('setConvQuestion', { param, questionDetail })
    },

    async loadPivotTable({ commit }, key: string) {
        const { data } = await getPivotTable(key)
        commit('setPivotTable', { key, data })
    },

    async setNavMenu({ commit }) {
        const { data } = await getNavMenu()
        commit('setNavMenu', data)
    },

    async updateConvQuestions({ commit, state }) {
        if (state.loadingConvQuestions) {
            return // Stop multiple trigger of GetQuestions
        }
        commit('setLoadingConvQuestions', true)
        const { data } = await getQuestions()
        commit('setNavMenu', data.nav)
        commit('setDashConvQuestions', data.questions)
        commit('setLoadingConvQuestions', false)
    },

    clearLeaderboards({ commit }) {
        commit('clearLeaderboards')
    },

    clearConvQuestions({ commit }) {
        commit('setDashConvQuestions', {})
    },

    // load all data and cache it for offline
    async loadOfflineDataForFeedback(
        { rootGetters },
        { has_mlp = false } = {}
    ) {
        const questionTypes = getQuestionTypeOptions().map(
            (option) => option.name
        )
        for (const questionType of questionTypes) {
            // 1. responses
            const { data } = await getResponses(
                rootGetters.offlineFilter(questionType),
                {
                    pagesize: OFFLINE_RESPONSE_COUNT,
                    offset: 0,
                }
            )

            if (!has_mlp) {
                // 2. nps summary
                await getNPSSummary(0, 'month', 1, undefined, questionType)

                // 3. stats
                await getNPSStatsSummary(questionType, 'month', 1)

                // 4. trend graph
                await getNPSTrendGraph(questionType)
            }
        }
    },

    async toggleResponseStar({ commit, state }, { key, questionId }) {
        let matchedResponse

        state.responsesMap[key].forEach((response) => {
            if (Number(response.id) === Number(questionId)) {
                response.isStarred = !response.isStarred
                matchedResponse = response
            }
        })

        await toggleResponseStarred(questionId).catch(() => {
            // Revert star if api call failed
            if (matchedResponse) {
                matchedResponse.isStarred = !matchedResponse.isStarred
            }
        })
    },

    async setCanViewFrontlineManagerDashboard({ commit }, canView: boolean) {
        commit('setCanViewFrontlineManagerDashboard', canView)
    },
}

const mutations: MutationTree<DashboardState> = {
    setNpsSummary(state, { npsSummary }) {
        state.npsSummary = npsSummary
    },

    setNpsStatsSummary(state, { npsStatsSummary }) {
        state.npsStatsSummary = npsStatsSummary
    },

    setNpsTrendGraph(state, { npsTrendGraph }) {
        state.npsTrendGraph = npsTrendGraph
    },

    setSentimentTrendGraph(state, { data, column }) {
        state.freeTextTrendGraphs = {
            ...state.freeTextTrendGraphs,
            [column]: data,
        }
    },

    setResponses(state, { key, data }) {
        state.responsesMap = { ...state.responsesMap, [key]: data }
    },

    // update current leader board to show in summary tab
    setSummaryCurrentLeaderboard(state, { summaryCurrentLeaderboard }) {
        state.summaryCurrentLeaderboard = summaryCurrentLeaderboard
    },

    setThemes(state, { key, themes }) {
        state.themesMap = { ...state.themesMap, [key]: themes }
    },

    setThemeSettings(state, { themeSettings }) {
        state.themeSettings = [...themeSettings]
    },

    setTheme(state, { key, themeId, data, includeTotal }) {
        if (!state.themesMap[key]) {
            state.themesMap[key] = {
                rows: {},
                total: 0,
                lastProcessed: 'just now',
            }
        }
        state.themesMap[key].rows[themeId] = data
        if (includeTotal) {
            state.themesMap[key].total = data.totalResponded
        }
        state.themesMap = { ...state.themesMap }
    },

    clearLeaderboards(state) {
        state.leaderboards = {}
    },

    setLeaderboard(state, { leaderboardName, data }) {
        // can't just set key: value, need to replace whole object so vue can detect it, ahhh!
        state.leaderboards = { ...state.leaderboards, [leaderboardName]: data }
    },

    setLeaderboardLoading(state, leaderboardLoading: boolean) {
        state.leaderboardLoading = leaderboardLoading
    },

    setLoadingConvQuestions(state, loadingConvQuestions: boolean) {
        state.loadingConvQuestions = loadingConvQuestions
    },

    setConvQuestion(state, { param, questionDetail }) {
        state.convQuestions = {
            ...state.convQuestions,
            [param]: questionDetail,
        }
    },

    setDashConvQuestions(state, convQuestions) {
        state.convQuestions = { ...convQuestions }
    },

    setPivotTable(state, { key, data }: { key: string; data: PivotTable }) {
        state.pivotTables = { ...state.pivotTables, [key]: data }
    },

    setNavMenu(state, data) {
        state.navMenu = { ...data }
    },

    setCanViewFrontlineManagerDashboard(state, canView: boolean) {
        state.canViewFrontlineManagerDashboard = canView
    },
}

if (process.env.CONFIG_KEY !== 'mobile') {
    // just a placeholder so in the component both web and mobile can work
    getters.fullDomainUrl = () => ''
    getters.hasNetworkConnection = () => true
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
