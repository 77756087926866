<template>
    <DatePicker
        v-model="range"
        mode="date"
        color="blue"
        :masks="masks"
        :first-day-of-week="2"
        :columns="getColumns()"
        is-range
        @update:modelValue="applyChange"
    >
        <template #default="{ inputValue, inputEvents, showPopover }">
            <input
                :value="inputValue.start"
                class="ask-date-range-input"
                v-on="inputEvents.start"
            />
            <img
                src="@/assets/img/Icons/Grey/Back.svg"
                class="ask-arrow-seperator"
            />
            <input
                :value="inputValue.end"
                class="ask-date-range-input"
                v-on="inputEvents.end"
            />
            {{ showDatePickerOnOpen(showPopover) }}
        </template>
    </DatePicker>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css'
import store from '@/store'

const dateFormat = 'DD-MMM-YYYY'

interface Props {
    from: Date
    to: Date
    onChange: ({ from, to }: { from: Date; to: Date }) => void
    startOpen?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    from: new Date(),
    to: new Date(),
    startOpen: false,
})

const isMobile = computed(() => store.getters.isMobile)

const startDatePickerOpen = ref(false)

const range = ref({
    start: props.from,
    end: props.to,
})

const masks = {
    input: dateFormat,
}

onMounted(() => {
    startDatePickerOpen.value = props.startOpen || false
})

const getColumns = () => {
    return !isMobile.value ? 2 : 1
}

const applyChange = () => {
    if (
        !range.value ||
        (range.value?.start === props.from && range.value?.end === props.to)
    ) {
        return
    }

    props.onChange({
        from: range.value.start,
        to: range.value.end,
    })
}

const showDatePickerOnOpen = (showPopover: Function) => {
    if (startDatePickerOpen.value) {
        showPopover()
        startDatePickerOpen.value = false
    }
}
</script>

<style lang="less" scoped>
@import '../styles/palette';

.ask-date-range-input {
    color: #666;
    padding: 5px 8px;
    width: 90px;
    font-size: 12px;
    background: @backgroundColor;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.ask-arrow-seperator {
    margin: 0 5px;
    height: 16px;
    width: 16px;
    transform: rotate(180deg);
}
</style>
