import { baseClient } from '@/api/client'
import { Nav } from '@/pages/dashboard/entities/nav'
import { ConvUIQuestion } from '@/pages/dashboard/entities/dash'

export async function getNavMenu() {
    return baseClient.get<Nav>(`/ajax/dash/menu`)
}

export async function getQuestions() {
    return baseClient.get<{ questions: ConvUIQuestion[]; nav: Nav }>(
        `/ajax/dash/questions`
    )
}
