<template>
    <div class="rank-row">
        <div class="rank">
            <div v-if="avatar" class="avatar-container">
                <img :src="getCDNUrl(avatar)" class="avatar" />
                <span v-if="showRank" class="rank-number" :class="{ active }">
                    {{ rankNum }}
                </span>
            </div>
            <div v-else class="rank-number" :class="{ active }">
                <template v-if="showRank">
                    {{ rankNum }}
                </template>
                <template v-else> &mdash; </template>
            </div>
        </div>
        <div v-if="showRank" class="body">
            <div class="value">{{ rank.name }}</div>
            <ComparisonBar
                :value="Number(rank.nps)"
                :average="Number(companyNps)"
                :average-text="`${ratingLabel} ${companyNps}`"
                :min="minValue"
                :max="maxValue"
                :showlabel="rankNum === 1"
                :last="isLast"
                positive-color="linear-gradient(90deg, rgba(46, 91, 255, 0.25) 8.85%, #0037FF 100%)"
                negative-color="linear-gradient(90deg, #F7C09C 0.52%, rgba(247, 192, 156, 0.05) 100%)"
                class="percent"
            />
        </div>
        <div v-else class="no-rank-body">
            <div class="value">{{ rank.name }}</div>
        </div>
        <div class="nps-info">
            <div class="nps">
                {{ nps }}
            </div>
            <div class="change">
                <img
                    v-if="rank.change >= 0"
                    alt=""
                    src="@/assets/img/Icons/leaderboard-up.svg"
                />
                <img
                    v-else
                    alt=""
                    src="@/assets/img/Icons/leaderboard-down.svg"
                />
                {{ change }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { ILeaderboardResult } from '@/entities'
import ComparisonBar from '@/components/ComparisonBar.vue'
import { getCDNUrl } from '@/utils/cdn'
import { Getter } from 'vuex-facing-decorator'

@Component({
    components: { ComparisonBar },
})
export default class ScorecardRankRow extends Vue {
    getCDNUrl = getCDNUrl

    @Prop({ type: Number }) public rankNum!: number
    @Prop({ type: Number }) public minValue!: number
    @Prop({ type: Number }) public maxValue!: number
    @Prop({ type: Number }) public companyNps!: number
    @Prop({ type: Boolean }) public active!: boolean
    @Prop({ type: Boolean }) public isLast!: boolean
    @Prop({ type: Boolean }) public isFiveScoreQuestionType!: boolean
    @Prop({ type: String }) public avatar!: string
    @Prop() public rank!: ILeaderboardResult
    @Getter public filterQuestionType
    @Getter public $companyVars

    public get ratingLabel() {
        return this.isFiveScoreQuestionType ? 'Rating' : 'NPS'
    }

    public get nps() {
        return this.isFiveScoreQuestionType
            ? Number(this.rank.nps ?? 0).toFixed(
                  this.$companyVars?.decimals[this.filterQuestionType] ?? 4
              )
            : Number(this.rank.nps ?? 0).toFixed(
                  this.$companyVars?.decimals['nps'] ?? 1
              )
    }

    public get change() {
        return this.isFiveScoreQuestionType
            ? Number(this.rank.change ?? 0).toFixed(
                  this.$companyVars?.decimals[this.filterQuestionType] ?? 4
              )
            : Number(this.rank.change ?? 0).toFixed(
                  this.$companyVars?.decimals['nps'] ?? 1
              )
    }

    public get showRank() {
        return this.rank.responded >= 5
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';

.rank-row {
    display: flex;
    align-items: center;
    min-height: 70px;

    .rank {
        width: 44px;

        .avatar-container {
            position: relative;

            .avatar {
                width: 44px;
                height: 44px;
                border-radius: 50%;
            }

            > .rank-number {
                position: absolute;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                color: #8097b1;
                background: #ffffff;
                box-shadow: 0px 3px 10px rgba(46, 91, 255, 0.297866);
                padding: 2px 5px;
                border-radius: 8px;
                right: -2px;
                top: -2px;

                &.active {
                    background: #043aff;
                    box-shadow: 0px 3px 10px rgba(46, 91, 255, 0.297866);
                    color: white;
                }
            }
        }

        > .rank-number {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #93a1be;
            min-width: 30px;

            &.active {
                color: #043aff;
            }
        }
    }

    .body {
        flex: 1;
        margin: 0 16px;
        position: relative;

        .value {
            font-size: 14px;
            line-height: 20px;
            color: #2e384d;
            position: absolute;
            top: 20px;
            z-index: 1;
        }
    }

    .no-rank-body {
        margin: 0 16px;
        flex: 1;
    }

    .nps-info {
        text-align: right;
        width: 45px;

        .nps {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: right;
            color: #2e384d;
        }

        .change {
            font-size: 11px;
            line-height: 20px;
            text-align: right;
            color: #8798ad;

            img {
                width: 8px;
            }
        }
    }
}
</style>
<style lang="less">
/* overwrite normal comparison bar style */
.rank-row {
    .percentage-container {
        max-width: 100% !important;
        height: 60px;
        padding-top: 20px;

        .average-label {
            background: white;
            font-size: 11px;
            color: #93a1be;
            width: 57px;
            padding: 0;
        }

        .the-percentage {
            background: #f4f6fc;
            box-shadow: none;
            height: 4px;
            border-radius: 2px;

            .percent {
                height: 4px;
                border-radius: 2px;
            }
        }

        .average.last {
            height: 60px;
        }
    }
}
</style>
