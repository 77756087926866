import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4adf6bef"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-content rank-disabled panel"
}
const _hoisted_2 = { class: "info-without-rank" }
const _hoisted_3 = { class: "nps" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "nps-info"
}
const _hoisted_12 = { class: "info" }
const _hoisted_13 = { class: "nps" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["src"]
const _hoisted_18 = ["src"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["src"]
const _hoisted_21 = {
  key: 1,
  class: "nps-info"
}
const _hoisted_22 = { class: "rank" }
const _hoisted_23 = {
  key: 0,
  class: "status-label"
}
const _hoisted_24 = ["src"]
const _hoisted_25 = { key: 1 }
const _hoisted_26 = {
  key: 0,
  class: "ordinal"
}
const _hoisted_27 = {
  key: 1,
  class: "ordinal"
}
const _hoisted_28 = { key: 2 }
const _hoisted_29 = { class: "ordinal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScorecardProfileImage = _resolveComponent("ScorecardProfileImage")!
  const _component_Typography = _resolveComponent("Typography")!

  return (!_ctx.scorecard.rankEnabled && !_ctx.scorecard.status)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ScorecardProfileImage, {
          scorecard: _ctx.scorecard,
          "can-edit": !_ctx.isTeamMemberView
        }, null, 8, ["scorecard", "can-edit"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Typography, {
            variant: "h4",
            class: "name"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.scorecardUserName), 1)
            ], undefined, true),
            _: 1
          }),
          _createVNode(_component_Typography, {
            variant: "caption",
            class: "location"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.locationText), 1)
            ], undefined, true),
            _: 1
          }),
          (_ctx.touring || _ctx.scorecard.nps !== undefined)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "nps-info",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('trend')))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Typography, {
                    variant: "h2",
                    class: "number"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.scorecard.nps !== undefined)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatScore(
                                    _ctx.scorecard.nps,
                                    false,
                                    false,
                                    _ctx.questionType,
                                    _ctx.$companyVars?.decimals
                                )), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.touring ? '54.1' : ''), 1))
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_Typography, {
                    variant: "body2",
                    class: "change"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.scorecard.nps !== undefined)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            (_ctx.scorecard.change >= 0)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  alt: "",
                                  src: _ctx.iconUp
                                }, null, 8, _hoisted_7))
                              : (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  alt: "",
                                  src: _ctx.iconDown
                                }, null, 8, _hoisted_8)),
                            _createTextVNode(" " + _toDisplayString(_ctx.formatScore(
                                    _ctx.scorecard.change,
                                    false,
                                    false,
                                    _ctx.questionType,
                                    _ctx.$companyVars?.decimals
                                )), 1)
                          ]))
                        : (_ctx.touring)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                              _createElementVNode("img", {
                                alt: "",
                                src: _ctx.iconUp
                              }, null, 8, _hoisted_10),
                              _createTextVNode(" 8.3 ")
                            ]))
                          : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11)),
          _createVNode(_component_Typography, {
            variant: "caption",
            class: "last30"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.questionType) + " Last 30 Days", 1)
            ], undefined, true),
            _: 1
          })
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["header-content", [{ 'has-status': !!_ctx.scorecard.status }, _ctx.scorecard.status]])
      }, [
        _createVNode(_component_Typography, {
          variant: "h4",
          class: "name"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.scorecardUserName), 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_Typography, {
          variant: "caption",
          class: "location"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.locationText), 1)
          ], undefined, true),
          _: 1
        }),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.touring || _ctx.scorecard.nps !== undefined)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "nps-info info-wing",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickTrend && _ctx.onClickTrend(...args)))
              }, [
                _createVNode(_component_Typography, {
                  variant: "overline",
                  class: "label"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.ratingLabel), 1)
                  ], undefined, true),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Typography, {
                    variant: "subtitle1",
                    class: "number"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.scorecard.nps !== undefined)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.formatScore(
                                    _ctx.scorecard.nps,
                                    false,
                                    false,
                                    _ctx.questionType,
                                    _ctx.$companyVars?.decimals
                                )), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.touring ? '54.1' : ''), 1))
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_Typography, {
                    variant: "body2",
                    class: "change"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.scorecard.nps !== undefined)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                            (_ctx.scorecard.change >= 0)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  alt: "",
                                  src: _ctx.iconUp
                                }, null, 8, _hoisted_17))
                              : (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  alt: "",
                                  src: _ctx.iconDown
                                }, null, 8, _hoisted_18)),
                            _createTextVNode(" " + _toDisplayString(_ctx.formatScore(
                                    _ctx.scorecard.change,
                                    false,
                                    false,
                                    _ctx.questionType,
                                    _ctx.$companyVars?.decimals
                                )), 1)
                          ]))
                        : (_ctx.touring)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                              _createElementVNode("img", {
                                alt: "",
                                src: _ctx.iconUp
                              }, null, 8, _hoisted_20),
                              _createTextVNode(" 8.3 ")
                            ]))
                          : _createCommentVNode("", true)
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_21)),
          _createVNode(_component_ScorecardProfileImage, {
            class: "scorecard-image",
            scorecard: _ctx.scorecard,
            "can-edit": !_ctx.isTeamMemberView
          }, null, 8, ["scorecard", "can-edit"]),
          _createElementVNode("div", {
            class: "rank-info info-wing",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickRank && _ctx.onClickRank(...args)))
          }, [
            _createVNode(_component_Typography, {
              variant: "overline",
              class: "label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.scorecard.status ? 'Status' : 'Rank'), 1)
              ], undefined, true),
              _: 1
            }),
            _createElementVNode("div", _hoisted_22, [
              (_ctx.scorecard.status)
                ? (_openBlock(), _createElementBlock("span", _hoisted_23, [
                    _createElementVNode("img", {
                      class: "medal-icon",
                      alt: "medal",
                      src: _ctx.medalIcon
                    }, null, 8, _hoisted_24),
                    _createVNode(_component_Typography, { variant: "body2" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.scorecard.status), 1)
                      ], undefined, true),
                      _: 1
                    })
                  ]))
                : (_ctx.rankValues && _ctx.rankValues.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                      _createTextVNode(_toDisplayString(_ctx.rankValues.join(', ')) + " ", 1),
                      (_ctx.rankValues.length === 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.getOrdinalRank(_ctx.rankValues[0])), 1))
                        : _createCommentVNode("", true),
                      (_ctx.rankValues.length >= 3)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_27, " ..."))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_28, [
                      _createTextVNode(_toDisplayString(_ctx.touring ? 1 : '-') + " ", 1),
                      _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.touring ? 'st' : ''), 1)
                    ]))
            ])
          ])
        ]),
        _createVNode(_component_Typography, {
          variant: "caption",
          class: "last30"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Last 30 Days")
          ], undefined, true),
          _: 1
        })
      ], 2))
}