import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79b550da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-title"
}
const _hoisted_2 = {
  key: 1,
  class: "card-title"
}
const _hoisted_3 = { class: "horizontal-scroll-container metrics-container" }
const _hoisted_4 = {
  key: 0,
  class: "scrolling-item"
}
const _hoisted_5 = {
  key: 1,
  class: "scrolling-item"
}
const _hoisted_6 = {
  key: 2,
  class: "scrolling-item"
}
const _hoisted_7 = {
  key: 3,
  class: "scrolling-item"
}
const _hoisted_8 = {
  key: 4,
  class: "scrolling-item"
}
const _hoisted_9 = {
  key: 5,
  class: "scrolling-item"
}
const _hoisted_10 = {
  key: 0,
  class: "card-title"
}
const _hoisted_11 = {
  key: 1,
  class: "card-title"
}
const _hoisted_12 = {
  key: 0,
  class: "card-title"
}
const _hoisted_13 = {
  key: 1,
  class: "card-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoLineHeader = _resolveComponent("TwoLineHeader")!
  const _component_InsightsTimePickerMobile = _resolveComponent("InsightsTimePickerMobile")!
  const _component_SkeletonDiv = _resolveComponent("SkeletonDiv")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_InsightsMetric = _resolveComponent("InsightsMetric")!
  const _component_InsightsGraph = _resolveComponent("InsightsGraph")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_InsightsCoachingTiles = _resolveComponent("InsightsCoachingTiles")!
  const _component_InsightsEngagementTiles = _resolveComponent("InsightsEngagementTiles")!
  const _component_Scrollable = _resolveComponent("Scrollable")!
  const _component_WebViewScreen = _resolveComponent("WebViewScreen")!

  return (_openBlock(), _createBlock(_component_WebViewScreen, {
    "no-pad": "",
    class: "insights",
    "disable-when-offline": ""
  }, {
    "custom-title": _withCtx(() => [
      _createVNode(_component_TwoLineHeader, {
        title: "Insights",
        subtitle: _ctx.friendlyTimeRange
      }, null, 8, ["subtitle"])
    ]),
    "right-button": _withCtx(() => [
      _createVNode(_component_InsightsTimePickerMobile, {
        "time-range": _ctx.timeRange,
        onSelectTime: _ctx.updateTimeRange
      }, null, 8, ["time-range", "onSelectTime"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Scrollable, {
        loading: _ctx.loading,
        "pull-down": _ctx.onPullDown
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseCard, {
            class: "card",
            "no-pad": "",
            "no-overflow": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["card-title-container insights-title", { 'currently-loading': _ctx.loading }])
              }, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_SkeletonDiv, { width: _ctx.skeleTitleWidth }, null, 8, ["width"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, "Growth")),
                _createVNode(_component_BaseButton, {
                  "new-secondary": "",
                  disabled: _ctx.loading,
                  click: 
                            () => _ctx.router.push({ name: 'insightsGrowthMetrics' })
                        ,
                  "data-id-attribute": "pendo-explore-growth"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Explore")
                  ], undefined, true),
                  _: 1
                }, 8, ["disabled", "click"])
              ], 2),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.activeMetrics.includes('nps'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_InsightsMetric, {
                        active: 
                                _ctx.selectedMetric === _ctx.InsightsMetrics.NPS &&
                                !_ctx.loading
                            ,
                        delta: _ctx.npsChangeDelta,
                        title: _ctx.nps,
                        subtitle: _ctx.InsightsMetrics.NPS,
                        skeleton: 
                                _ctx.loading || _ctx.currentlyLoading.includes('nps')
                            ,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickMetric(_ctx.InsightsMetrics.NPS, 'nps')))
                      }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showFivestarTile)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_InsightsMetric, {
                        active: 
                                _ctx.selectedMetric === _ctx.InsightsMetrics.FIVESTAR &&
                                !_ctx.loading
                            ,
                        delta: _ctx.fivestarChangeDelta,
                        title: _ctx.fivestarRating,
                        subtitle: _ctx.InsightsMetrics.FIVESTAR,
                        skeleton: 
                                _ctx.loading || _ctx.currentlyLoading.includes('fivestar')
                            ,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (
                                _ctx.onClickMetric(
                                    _ctx.InsightsMetrics.FIVESTAR,
                                    'fivestar'
                                )
                            ))
                      }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showCsatTile)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_InsightsMetric, {
                        active: 
                                _ctx.selectedMetric === _ctx.InsightsMetrics.CSAT &&
                                !_ctx.loading
                            ,
                        delta: _ctx.csatChangeDelta,
                        title: _ctx.csatRating,
                        subtitle: _ctx.InsightsMetrics.CSAT,
                        skeleton: 
                                _ctx.loading || _ctx.currentlyLoading.includes('csat')
                            ,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickMetric(_ctx.InsightsMetrics.CSAT, 'csat')))
                      }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$companyVars.enable_insights_repeat_customers)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_InsightsMetric, {
                        active: 
                                _ctx.selectedMetric ===
                                    _ctx.InsightsMetrics.REPEAT_VISIT && !_ctx.loading
                            ,
                        delta: _ctx.repeatVisitDelta,
                        title: `${_ctx.repeatCustomer}%`,
                        subtitle: _ctx.InsightsMetrics.REPEAT_VISIT,
                        skeleton: 
                                _ctx.loading ||
                                _ctx.currentlyLoading.includes('repeatVisits')
                            ,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClickMetric(_ctx.InsightsMetrics.REPEAT_VISIT)))
                      }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$companyVars.enable_insights_retention)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_InsightsMetric, {
                        active: 
                                _ctx.selectedMetric === _ctx.InsightsMetrics.RETENTION &&
                                !_ctx.loading
                            ,
                        delta: _ctx.customerRetentionDelta,
                        title: `${_ctx.customerRetention}%`,
                        subtitle: _ctx.InsightsMetrics.RETENTION,
                        skeleton: 
                                _ctx.loading ||
                                _ctx.currentlyLoading.includes('repeatVisits')
                            ,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClickMetric(_ctx.InsightsMetrics.RETENTION)))
                      }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$companyVars.enable_insights_tenure)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_InsightsMetric, {
                        active: 
                                _ctx.selectedMetric === _ctx.InsightsMetrics.TENURE &&
                                !_ctx.loading
                            ,
                        delta: _ctx.customTenureDelta,
                        title: _ctx.customerTenure,
                        subtitle: _ctx.InsightsMetrics.TENURE,
                        skeleton: 
                                _ctx.loading ||
                                _ctx.currentlyLoading.includes('repeatVisits')
                            ,
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onClickMetric(_ctx.InsightsMetrics.TENURE)))
                      }, null, 8, ["active", "delta", "title", "subtitle", "skeleton"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.growthGraphData && _ctx.growthGraphData.length)
                ? (_openBlock(), _createBlock(_component_InsightsGraph, {
                    key: 0,
                    title: _ctx.growthGraphTitle,
                    data: _ctx.growthGraphData,
                    color: _ctx.$palette.insightsGrowth
                  }, null, 8, ["title", "data", "color"]))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          }),
          (_ctx.showCoachingTiles)
            ? (_openBlock(), _createBlock(_component_BaseCard, {
                key: 0,
                class: "card",
                "no-pad": "",
                "no-overflow": ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["card-title-container coaching-title", { 'currently-loading': _ctx.loading }])
                  }, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_SkeletonDiv, { width: _ctx.skeleTitleWidth }, null, 8, ["width"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, "Coaching")),
                    _createVNode(_component_BaseButton, {
                      "new-secondary": "",
                      disabled: _ctx.loading,
                      click: 
                            () =>
                                _ctx.router.push({
                                    name: 'InsightsCoachingMetrics',
                                })
                        ,
                      "data-id-attribute": "pendo-explore-coaching"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Explore ")
                      ], undefined, true),
                      _: 1
                    }, 8, ["disabled", "click"])
                  ], 2),
                  _createVNode(_component_InsightsCoachingTiles, {
                    "coaching-metric-type": _ctx.selectedCoachingMetric,
                    "with-graph": "",
                    onClick: _ctx.changeCoachingMetricType
                  }, null, 8, ["coaching-metric-type", "onClick"])
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.$companyVars.has_insights_v3 && _ctx.showEngagementTiles)
            ? (_openBlock(), _createBlock(_component_BaseCard, {
                key: 1,
                class: "card",
                "no-pad": "",
                "no-overflow": ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["card-title-container engagement-title", { 'currently-loading': _ctx.loading }])
                  }, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_SkeletonDiv, { width: _ctx.skeleTitleWidth }, null, 8, ["width"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_13, "Engagement")),
                    _createVNode(_component_BaseButton, {
                      "new-secondary": "",
                      disabled: _ctx.loading,
                      click: 
                            () =>
                                _ctx.router.push({
                                    name: 'InsightsEngagementMetrics',
                                })
                        ,
                      "data-id-attribute": "pendo-explore-engagement"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Explore ")
                      ], undefined, true),
                      _: 1
                    }, 8, ["disabled", "click"])
                  ], 2),
                  _createVNode(_component_InsightsEngagementTiles, { "has-graph": "" })
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], undefined, true),
        _: 1
      }, 8, ["loading", "pull-down"])
    ], undefined, true),
    _: 1
  }))
}