<template>
    <!--  layout without ranking and there is no scorecard status -->
    <div
        v-if="!scorecard.rankEnabled && !scorecard.status"
        class="header-content rank-disabled panel"
    >
        <ScorecardProfileImage
            :scorecard="scorecard"
            :can-edit="!isTeamMemberView"
        />
        <div class="info-without-rank">
            <Typography variant="h4" class="name">{{
                scorecardUserName
            }}</Typography>
            <Typography variant="caption" class="location">{{
                locationText
            }}</Typography>
            <div
                v-if="touring || scorecard.nps !== undefined"
                class="nps-info"
                @click="$emit('trend')"
            >
                <div class="nps">
                    <Typography variant="h2" class="number">
                        <span v-if="scorecard.nps !== undefined">
                            {{
                                formatScore(
                                    scorecard.nps,
                                    false,
                                    false,
                                    questionType,
                                    $companyVars?.decimals
                                )
                            }}
                        </span>
                        <span v-else>{{ touring ? '54.1' : '' }}</span>
                    </Typography>
                    <Typography variant="body2" class="change">
                        <span v-if="scorecard.nps !== undefined">
                            <img
                                v-if="scorecard.change >= 0"
                                alt=""
                                :src="iconUp"
                            />
                            <img v-else alt="" :src="iconDown" />
                            {{
                                formatScore(
                                    scorecard.change,
                                    false,
                                    false,
                                    questionType,
                                    $companyVars?.decimals
                                )
                            }}
                        </span>
                        <span v-else-if="touring">
                            <img alt="" :src="iconUp" /> 8.3
                        </span>
                    </Typography>
                </div>
            </div>
            <div v-else class="nps-info" />
            <Typography variant="caption" class="last30">{{ questionType }} Last 30 Days</Typography>
        </div>
    </div>
    <!--  layout with ranking -->
    <div
        v-else
        class="header-content"
        :class="[{ 'has-status': !!scorecard.status }, scorecard.status]"
    >
        <Typography variant="h4" class="name">{{
            scorecardUserName
        }}</Typography>
        <Typography variant="caption" class="location">{{
            locationText
        }}</Typography>
        <div class="info">
            <div
                v-if="touring || scorecard.nps !== undefined"
                class="nps-info info-wing"
                @click="onClickTrend"
            >
                <Typography variant="overline" class="label">{{
                    ratingLabel
                }}</Typography>
                <div class="nps">
                    <Typography variant="subtitle1" class="number">
                        <span v-if="scorecard.nps !== undefined">
                            {{
                                formatScore(
                                    scorecard.nps,
                                    false,
                                    false,
                                    questionType,
                                    $companyVars?.decimals
                                )
                            }}
                        </span>
                        <span v-else>{{ touring ? '54.1' : '' }}</span>
                    </Typography>
                    <Typography variant="body2" class="change">
                        <span v-if="scorecard.nps !== undefined">
                            <img
                                v-if="scorecard.change >= 0"
                                alt=""
                                :src="iconUp"
                            />
                            <img v-else alt="" :src="iconDown" />
                            {{
                                formatScore(
                                    scorecard.change,
                                    false,
                                    false,
                                    questionType,
                                    $companyVars?.decimals
                                )
                            }}
                        </span>
                        <span v-else-if="touring">
                            <img alt="" :src="iconUp" /> 8.3
                        </span>
                    </Typography>
                </div>
            </div>
            <div v-else class="nps-info" />
            <ScorecardProfileImage
                class="scorecard-image"
                :scorecard="scorecard"
                :can-edit="!isTeamMemberView"
            />
            <div class="rank-info info-wing" @click="onClickRank">
                <Typography variant="overline" class="label">
                    {{ scorecard.status ? 'Status' : 'Rank' }}
                </Typography>
                <div class="rank">
                    <span v-if="scorecard.status" class="status-label">
                        <img class="medal-icon" alt="medal" :src="medalIcon" />
                        <Typography variant="body2">{{
                            scorecard.status
                        }}</Typography>
                    </span>
                    <span v-else-if="rankValues && rankValues.length">
                        {{ rankValues.join(', ') }}
                        <span v-if="rankValues.length === 1" class="ordinal">{{
                            getOrdinalRank(rankValues[0])
                        }}</span>
                        <span v-if="rankValues.length >= 3" class="ordinal">
                            ...</span>
                    </span>
                    <span v-else>
                        {{ touring ? 1 : '-' }}
                        <span class="ordinal">{{ touring ? 'st' : '' }}</span>
                    </span>
                </div>
            </div>
        </div>
        <Typography variant="caption" class="last30">Last 30 Days</Typography>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { decode as entityDecode } from 'he'

import ScorecardProfileImage from '@/components/Scorecard/ScorecardProfileImage.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { ScorecardEntity } from '@/entities/scorecard'
import { formatScore, getOrdinal } from '@/utils/number'

import IconUp from '@/assets/img/Icons/leaderboard-up.svg'
import IconDown from '@/assets/img/Icons/leaderboard-down.svg'
import IconMedalGold from '@/assets/img/Icons/medal-gold.svg'
import IconMedalSilver from '@/assets/img/Icons/medal-silver.svg'
import IconMedalBronze from '@/assets/img/Icons/medal-bronze.svg'
import { Getter } from 'vuex-facing-decorator'

@Component({
    methods: { formatScore },
    components: { ScorecardProfileImage, Typography },
    emits: ['trend', 'rank'],
})
export default class ScorecardHeader extends Vue {
    iconUp = IconUp
    iconDown = IconDown

    @Prop({ type: Object }) public scorecard!: ScorecardEntity
    @Prop({ type: Boolean, required: false }) public touring?: boolean
    @Prop({ type: Boolean, required: false }) public isTeamMemberView?: boolean
    @Prop({ type: String }) public ratingLabel!: string
    @Prop({ type: String }) public locationText!: string
    @Getter public filterQuestionType
    @Getter public $companyVars

    public get rankValues() {
        return this.scorecard.rankValues.filter((rank) => rank > 0).slice(0, 3)
    }

    public get questionType() {
        return this.scorecard.questionType ?? ''
    }

    private get scorecardUserName(): string {
        return entityDecode(this.scorecard?.user?.name ?? '')
    }

    public getOrdinalRank(rank: string | number) {
        return getOrdinal(rank)
    }

    get medalIcon() {
        switch (this.scorecard.status) {
            case 'gold':
                return IconMedalGold
            case 'silver':
                return IconMedalSilver
            case 'bronze':
                return IconMedalBronze
            default:
                return IconMedalBronze
        }
    }

    onClickRank() {
        if (this.scorecard.status && this.scorecard.status !== 'gold') {
            return
        }
        this.$emit('rank')
    }

    onClickTrend() {
        this.$emit('trend')
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/layout';
@import '~@/styles/palette';
@import '~@/styles/scorecard/header';

.header-content {
    .nps-info {
        cursor: pointer;
        display: flex;
    }

    .rank-info {
        display: flex;
        cursor: pointer;
    }
    // different styles applied when show scorecard status
    &.has-status {
        padding: 20px 0;

        .name {
            color: @black80;
        }

        .location,
        .rank .label,
        .nps-info .label,
        .last30 {
            color: @grey40;
        }

        .status-label {
            display: inline-flex;
            text-transform: capitalize;

            .medal-icon {
                margin-right: 5px;
                height: 20px;
            }
        }
    }
}
</style>

<style lang="less">
.has-status .avatar {
    border: 4px white solid;
}
</style>
