import { GetterTree, MutationTree } from 'vuex'

export interface FrontlineManagerDashState {
    selectedScorecardId: number
}

export const state: FrontlineManagerDashState = {
    selectedScorecardId: 0,
}

const getters: GetterTree<FrontlineManagerDashState, any> = {
    selectedScorecardId: ({ selectedScorecardId }) => selectedScorecardId,
}

const mutations: MutationTree<FrontlineManagerDashState> = {
    setSelectedScorecardId(state, id: number) {
        state.selectedScorecardId = id
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
