<template>
    <WebViewScreen no-pad class="insights" disable-when-offline>
        <template #custom-title>
            <TwoLineHeader title="Insights" :subtitle="friendlyTimeRange" />
        </template>
        <template #right-button>
            <InsightsTimePickerMobile
                :time-range="timeRange"
                @selectTime="updateTimeRange"
            />
        </template>
        <Scrollable :loading="loading" :pull-down="onPullDown">
            <BaseCard class="card" no-pad no-overflow>
                <div
                    class="card-title-container insights-title"
                    :class="{ 'currently-loading': loading }"
                >
                    <div v-if="loading" class="card-title">
                        <SkeletonDiv :width="skeleTitleWidth" />
                    </div>
                    <div v-else class="card-title">Growth</div>
                    <BaseButton
                        new-secondary
                        :disabled="loading"
                        :click="
                            () => router.push({ name: 'insightsGrowthMetrics' })
                        "
                        data-id-attribute="pendo-explore-growth"
                        >Explore</BaseButton>
                </div>
                <div class="horizontal-scroll-container metrics-container">
                    <div
                        v-if="activeMetrics.includes('nps')"
                        class="scrolling-item"
                    >
                        <InsightsMetric
                            :active="
                                selectedMetric === InsightsMetrics.NPS &&
                                !loading
                            "
                            :delta="npsChangeDelta"
                            :title="nps"
                            :subtitle="InsightsMetrics.NPS"
                            :skeleton="
                                loading || currentlyLoading.includes('nps')
                            "
                            @click="onClickMetric(InsightsMetrics.NPS, 'nps')"
                        />
                    </div>
                    <div v-if="showFivestarTile" class="scrolling-item">
                        <InsightsMetric
                            :active="
                                selectedMetric === InsightsMetrics.FIVESTAR &&
                                !loading
                            "
                            :delta="fivestarChangeDelta"
                            :title="fivestarRating"
                            :subtitle="InsightsMetrics.FIVESTAR"
                            :skeleton="
                                loading || currentlyLoading.includes('fivestar')
                            "
                            @click="
                                onClickMetric(
                                    InsightsMetrics.FIVESTAR,
                                    'fivestar'
                                )
                            "
                        />
                    </div>
                    <div v-if="showCsatTile" class="scrolling-item">
                        <InsightsMetric
                            :active="
                                selectedMetric === InsightsMetrics.CSAT &&
                                !loading
                            "
                            :delta="csatChangeDelta"
                            :title="csatRating"
                            :subtitle="InsightsMetrics.CSAT"
                            :skeleton="
                                loading || currentlyLoading.includes('csat')
                            "
                            @click="onClickMetric(InsightsMetrics.CSAT, 'csat')"
                        />
                    </div>
                    <div
                        v-if="$companyVars.enable_insights_repeat_customers"
                        class="scrolling-item"
                    >
                        <InsightsMetric
                            :active="
                                selectedMetric ===
                                    InsightsMetrics.REPEAT_VISIT && !loading
                            "
                            :delta="repeatVisitDelta"
                            :title="`${repeatCustomer}%`"
                            :subtitle="InsightsMetrics.REPEAT_VISIT"
                            :skeleton="
                                loading ||
                                currentlyLoading.includes('repeatVisits')
                            "
                            @click="onClickMetric(InsightsMetrics.REPEAT_VISIT)"
                        />
                    </div>
                    <div
                        v-if="$companyVars.enable_insights_retention"
                        class="scrolling-item"
                    >
                        <InsightsMetric
                            :active="
                                selectedMetric === InsightsMetrics.RETENTION &&
                                !loading
                            "
                            :delta="customerRetentionDelta"
                            :title="`${customerRetention}%`"
                            :subtitle="InsightsMetrics.RETENTION"
                            :skeleton="
                                loading ||
                                currentlyLoading.includes('repeatVisits')
                            "
                            @click="onClickMetric(InsightsMetrics.RETENTION)"
                        />
                    </div>
                    <div
                        v-if="$companyVars.enable_insights_tenure"
                        class="scrolling-item"
                    >
                        <InsightsMetric
                            :active="
                                selectedMetric === InsightsMetrics.TENURE &&
                                !loading
                            "
                            :delta="customTenureDelta"
                            :title="customerTenure"
                            :subtitle="InsightsMetrics.TENURE"
                            :skeleton="
                                loading ||
                                currentlyLoading.includes('repeatVisits')
                            "
                            @click="onClickMetric(InsightsMetrics.TENURE)"
                        />
                    </div>
                </div>
                <InsightsGraph
                    v-if="growthGraphData && growthGraphData.length"
                    :title="growthGraphTitle"
                    :data="growthGraphData"
                    :color="$palette.insightsGrowth"
                ></InsightsGraph>
            </BaseCard>
            <BaseCard v-if="showCoachingTiles" class="card" no-pad no-overflow>
                <div
                    class="card-title-container coaching-title"
                    :class="{ 'currently-loading': loading }"
                >
                    <div v-if="loading" class="card-title">
                        <SkeletonDiv :width="skeleTitleWidth" />
                    </div>
                    <div v-else class="card-title">Coaching</div>
                    <BaseButton
                        new-secondary
                        :disabled="loading"
                        :click="
                            () =>
                                router.push({
                                    name: 'InsightsCoachingMetrics',
                                })
                        "
                        data-id-attribute="pendo-explore-coaching"
                    >
                        Explore
                    </BaseButton>
                </div>
                <InsightsCoachingTiles
                    :coaching-metric-type="selectedCoachingMetric"
                    with-graph
                    @click="changeCoachingMetricType"
                />
            </BaseCard>
            <BaseCard
                v-if="$companyVars.has_insights_v3 && showEngagementTiles"
                class="card"
                no-pad
                no-overflow
            >
                <div
                    class="card-title-container engagement-title"
                    :class="{ 'currently-loading': loading }"
                >
                    <div v-if="loading" class="card-title">
                        <SkeletonDiv :width="skeleTitleWidth" />
                    </div>
                    <div v-else class="card-title">Engagement</div>
                    <BaseButton
                        new-secondary
                        :disabled="loading"
                        :click="
                            () =>
                                router.push({
                                    name: 'InsightsEngagementMetrics',
                                })
                        "
                        data-id-attribute="pendo-explore-engagement"
                    >
                        Explore
                    </BaseButton>
                </div>
                <InsightsEngagementTiles has-graph />
            </BaseCard>
        </Scrollable>
    </WebViewScreen>
</template>

<script lang="ts">
import BaseButton from '@/components/BaseButton.vue'
import BaseCard from '@/components/BaseCard.vue'
import Scrollable from '@/components/Scrollable.vue'
import WebViewScreen from '@/components/WebView/WebViewScreen.vue'
import { CompanyFeatures } from '@/entities'
import {
    CoachingInsights,
    CoachingMetricType,
    EngagementInsights,
    ITimeRange,
} from '@/entities/insights'
import { Component, Vue } from 'vue-facing-decorator'
import { useRouter } from 'vue-router'
import { Action, Getter } from 'vuex-facing-decorator'
import InsightsTimePickerMobile from '@/mobile/src/components/Insights/InsightsTimePickerMobile.vue'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import { InsightsMetric as InsightsMetrics } from '@/mobile/src/types/insights'
import {
    TsGroupRepeatVisitsSummaryEntity,
    TsGroupSummaryEntity,
    TsNpsSummaryEntity,
    TsTeamGroupEntity,
} from '@/entities/teamscoreboard'
import { friendlyCustomerTenure } from '@/mobile/src/utils/insights'
import InsightsCoachingTiles from '@/mobile/src/components/Insights/InsightsCoachingTiles.vue'
import InsightsGraph from '@/mobile/src/components/Insights/InsightsGraph.vue'
import SkeletonDiv from '@/components/Skeletons/SkeletonDiv.vue'
import TwoLineHeader from '@/mobile/src/components/TwoLineHeader.vue'
import InsightsEngagementTiles from '@/mobile/src/components/Insights/InsightsEngagementTiles.vue'
import { formatScore } from '@/utils/number'

@Component({
    components: {
        InsightsEngagementTiles,
        InsightsCoachingTiles,
        InsightsMetric,
        InsightsGraph,
        BaseButton,
        BaseCard,
        InsightsTimePickerMobile,
        Scrollable,
        WebViewScreen,
        SkeletonDiv,
        TwoLineHeader,
    },
})
export default class InsightsLanding extends Vue {
    public skeleTitleWidth = '80px'

    private router = useRouter()

    readonly InsightsMetrics = InsightsMetrics

    @Getter
    readonly timeRange!: ITimeRange

    @Getter
    readonly friendlyTimeRange!: string

    @Getter
    readonly friendlyShortTimeRange!: string

    @Getter
    readonly selectedMetric!: InsightsMetrics

    @Getter
    readonly insightsCoaching?: CoachingInsights

    @Getter
    readonly insightsEngagement?: EngagementInsights

    @Getter
    readonly npsInsightsSummary!: TsNpsSummaryEntity

    @Getter
    readonly fivestarInsightsSummary!: TsNpsSummaryEntity

    @Getter
    readonly csatInsightsSummary!: TsNpsSummaryEntity

    @Getter
    readonly viewByGroupType!: TsTeamGroupEntity

    @Getter
    readonly selectedCoachingMetric!: CoachingMetricType

    @Getter
    readonly insightsRepeatVisits!: TsGroupRepeatVisitsSummaryEntity

    @Getter
    readonly isLoadingInsightsData!: boolean

    @Getter
    readonly activeMetrics!: string[]

    @Getter
    readonly currentlyLoading!: string[]

    @Getter
    readonly hasFivestarData

    @Getter
    readonly hasCsatData

    @Getter
    readonly $companyVars

    @Action
    refreshData

    @Action
    loadTeamHierarchy

    @Action
    updateTimeRange

    @Action
    selectMetric

    @Action
    loadInsightsCoaching

    @Action
    setSelectedCoachingMetric

    get loading() {
        return this.isLoadingInsightsData || this.currentlyLoading.length > 0
    }

    public changeCoachingMetricType(metric) {
        this.setSelectedCoachingMetric(metric)
    }

    async mounted() {
        // load team hierarchy if not loaded
        if (!this.viewByGroupType) {
            await this.loadTeamHierarchy()
        }

        await this.refreshData()
    }

    onClickMetric(selected: InsightsMetrics, questionType) {
        // update user select metric
        if (!this.loading) {
            this.selectMetric({ selected, questionType })
        }
    }

    get showCoachingTiles() {
        return !!(
            this.insightsCoaching &&
            this.insightsCoaching?.success &&
            this.insightsCoaching?.data['scorecard'] &&
            this.insightsCoaching.data[
                this.insightsCoaching.front_line_staff_columns[0]
            ]
        )
    }

    get showEngagementTiles() {
        return !!(
            this.insightsEngagement &&
            this.insightsEngagement?.data['team'].length > 0
        )
    }

    get nps() {
        return this.npsInsightsSummary?.nps.toFixed(1) ?? '0.0'
    }

    get fivestarRating() {
        return formatScore(
            this.fivestarInsightsSummary?.nps ?? '0.0',
            true,
            false,
            'fivestar',
            this.$companyVars?.decimals
        )
    }

    get csatRating() {
        let csatRating = formatScore(
            this.csatInsightsSummary?.nps ?? '0.0',
            true,
            this.$companyVars.has_display_csat_as_percentage,
            'csat',
            this.$companyVars?.decimals
        )

        if (this.$companyVars.has_display_csat_as_percentage) {
            csatRating += '%'
        }

        return csatRating
    }

    get repeatCustomer() {
        return (
            this.insightsRepeatVisits?.repeatVisitPercentage.toFixed(1) ?? '0.0'
        )
    }

    get customerRetention() {
        return this.insightsRepeatVisits?.customerRetention.toFixed(1) ?? '0.0'
    }

    get customerTenure() {
        return friendlyCustomerTenure(this.insightsRepeatVisits?.customerTenure)
    }

    get growthGraphData() {
        switch (this.selectedMetric) {
            case InsightsMetrics.NPS:
                return this.npsInsightsSummary?.graph?.values ?? []
            case InsightsMetrics.CSAT:
                return this.csatInsightsSummary?.graph?.values ?? []
            case InsightsMetrics.FIVESTAR:
                return this.fivestarInsightsSummary?.graph?.values ?? []
            case InsightsMetrics.REPEAT_VISIT:
                return (
                    this.insightsRepeatVisits?.graph?.repeatVisitPercentage ??
                    []
                )
            case InsightsMetrics.RETENTION:
                return this.insightsRepeatVisits?.graph?.customerRetention ?? []
            case InsightsMetrics.TENURE:
                return this.insightsRepeatVisits?.graph?.customerTenure ?? []
            default:
                return null
        }
    }

    get showFivestarTile() {
        return (
            this.activeMetrics.includes('fivestar') &&
            (this.hasFivestarData || this.fivestarInsightsSummary?.total)
        )
    }

    get showCsatTile() {
        return (
            this.activeMetrics.includes('csat') &&
            (this.hasCsatData || this.csatInsightsSummary?.total)
        )
    }

    get growthGraphTitle() {
        return this.selectedMetric + ' ' + this.friendlyShortTimeRange
    }

    get npsChangeDelta() {
        return (this.npsInsightsSummary?.change ?? 0) >= 0
            ? 'positive'
            : 'negative'
    }

    get csatChangeDelta() {
        return (this.fivestarInsightsSummary?.change ?? 0) >= 0
            ? 'positive'
            : 'negative'
    }

    get fivestarChangeDelta() {
        return (this.csatInsightsSummary?.change ?? 0) >= 0
            ? 'positive'
            : 'negative'
    }

    get repeatVisitDelta() {
        return (this.insightsRepeatVisits?.repeatVisitPercentageDelta ?? 0) >= 0
            ? 'positive'
            : 'negative'
    }

    get customerRetentionDelta() {
        return (this.insightsRepeatVisits?.customerRetentionDelta ?? 0) >= 0
            ? 'positive'
            : 'negative'
    }

    get customTenureDelta() {
        return (this.insightsRepeatVisits?.customerTenureDelta ?? 0) >= 0
            ? 'positive'
            : 'negative'
    }

    onPullDown() {
        return this.refreshData(true)
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';
@import '~@/styles/button';

.insights {
    background-color: @neutralBg;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: @gutterSpacing;
    margin-bottom: 2 * @gutterSpacing;

    > .primary-action {
        flex: none;
    }
}

.heading {
    .heading();

    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.primary-action {
    margin-left: @gutterSpacing;
    text-transform: uppercase;
    white-space: nowrap;
}

.filters {
    position: relative;

    > .caret {
        position: absolute;
        top: -2px;
        right: 6px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.filter-title {
    .title();
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 0 @gutterSpacing 8px @gutterSpacing;

    .filter-value {
        color: @grey90;
        font-weight: 900;
    }
}

.card {
    margin: 12px 16px;
    .card-title-container {
        border-top: 4px transparent;
        background-clip: content-box, border-box;
        padding: @gutterSpacing;
        display: flex;
        .card-title {
            line-height: 32px;
            flex: 1;
            font-size: 20px;
            font-weight: 500;
        }
        &.insights-title {
            background-image: linear-gradient(white, white),
                linear-gradient(180deg, @insightsGrowth, #fff0 15%, #fff0);
        }
        &.coaching-title {
            background-image: linear-gradient(white, white),
                linear-gradient(180deg, @insightsCoaching, #fff0 15%, #fff0);
        }
        &.engagement-title {
            background-image: linear-gradient(white, white),
                linear-gradient(180deg, @insightsEngagement, #fff0 15%, #fff0);
        }
        &.currently-loading {
            background-image: linear-gradient(white, white),
                linear-gradient(180deg, @insightsLoading, #fff0 15%, #fff0);
        }
    }
}
.horizontal-scroll-container {
    margin-top: @gutterSpacing;
    margin-bottom: @gutterSpacing;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.scrolling-item {
    flex: 0 0 auto;
    padding-right: @gutterSpacing;

    &:first-child {
        padding-left: @gutterSpacing;
    }
}
</style>
