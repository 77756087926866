<template>
    <div class="feedback-top-overview-container">
        <LoadingCard
            v-if="loading || forceShowLoading"
            :borderless="true"
            variant="default"
        />
        <div v-else-if="!npsSummary" id="no-data">
            <EmptyPageState colour="white">
                <template #icon-img><FontAwesomeIcon id="error-icon" :icon="faWarning" /></template>
                <template #header-text>No overview data available</template>
                <template #body-text>There was an issue loading your feedback summary</template>
            </EmptyPageState>
        </div>
        <div v-else class="feedback-top-overview">
            <div class="content carousel-container">
                <Carousel
                    @touchstart="onCarouselTouchStart"
                    @touchend="onCarouselTouchEnd"
                >
                    <div key="summary" class="summary">
                        <div class="title">
                            {{
                                mobileFilterActive
                                    ? getOverviewText(
                                          mobileQuestionType,
                                          mobileFilterActive.time_unit,
                                          mobileFilterActive.time_value
                                      )
                                    : ''
                            }}
                        </div>
                        <div class="summary-score">
                            <Typography
                                key="score"
                                component="h1"
                                variant="h1"
                                class="nps-big"
                            >
                                {{
                                    formatScore(
                                        npsSummary.nps,
                                        isFiveScoreQuestionType(
                                            mobileQuestionType
                                        ),
                                        $companyVars.has_display_csat_as_percentage,
                                        mobileQuestionType,
                                        $companyVars?.decimals
                                    )
                                }}{{ npsSummary.percentageSign }}
                            </Typography>
                            <div v-if="npsChange" class="nps-change">
                                Change
                                {{
                                    formatScore(
                                        npsChange,
                                        isFiveScoreQuestionType(
                                            mobileQuestionType
                                        ),
                                        false,
                                        mobileQuestionType,
                                        $companyVars?.decimals
                                    )
                                }}
                                <img
                                    v-if="npsChange"
                                    class="trend-caret-medium"
                                    :src="npsChangeIcon"
                                />
                            </div>
                        </div>
                    </div>
                    <div key="responses">
                        <div class="title">
                            {{
                                mobileFilterActive
                                    ? getOverviewText(
                                          mobileQuestionType,
                                          mobileFilterActive.time_unit,
                                          mobileFilterActive.time_value,
                                          false
                                      )
                                    : ''
                            }}
                        </div>
                        <div v-if="npsStatsSummary" class="responses-stats">
                            <div class="stat-line">
                                <div>{{ metricLabel('PROMOTER') }}</div>
                                <div>
                                    {{
                                        npsStatsSummary.promoter.toLocaleString()
                                    }}
                                </div>
                            </div>
                            <div class="stat-line">
                                <div>{{ metricLabel('PASSIVE') }}</div>
                                <div>
                                    {{
                                        npsStatsSummary.passive.toLocaleString()
                                    }}
                                </div>
                            </div>
                            <div class="stat-line">
                                <div>{{ metricLabel('DETRACTOR') }}</div>
                                <div>
                                    {{
                                        npsStatsSummary.detractor.toLocaleString()
                                    }}
                                </div>
                            </div>
                            <div class="stat-line">
                                <div>Total Responses</div>
                                <div>
                                    {{ npsStatsSummary.total.toLocaleString() }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div key="surveys">
                        <div class="title">
                            {{
                                mobileFilterActive
                                    ? getOverviewText(
                                          mobileQuestionType,
                                          mobileFilterActive.time_unit,
                                          mobileFilterActive.time_value,
                                          false
                                      )
                                    : ''
                            }}
                        </div>
                        <div class="survey-stats">
                            <div class="stat-line">
                                <div>Sent</div>
                                <div>
                                    {{ npsStatsSummary.sent.toLocaleString() }}
                                </div>
                            </div>
                            <div class="stat-line">
                                <div>Opened</div>
                                <div>
                                    {{
                                        npsStatsSummary.opened.toLocaleString()
                                    }}
                                </div>
                            </div>
                            <div class="stat-line">
                                <div>Responded</div>
                                <div>
                                    {{
                                        npsStatsSummary.responded.toLocaleString()
                                    }}
                                </div>
                            </div>
                            <div class="stat-line">
                                <div>Response Rate</div>
                                <div>
                                    {{
                                        npsStatsSummary.responseRate.toFixed()
                                    }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
        <hr />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import Carousel from '@/components/Rain/Layouts/Carousel/Carousel.vue'
import { INPSStatsSummary, INPSSummary } from '@/entities'
import IconChangeUp from '@/assets/img/Icons/leaderboard-up.svg'
import IconChangeDown from '@/assets/img/Icons/leaderboard-down.svg'
import { FIVESCORE_TYPE, NPS_TYPE } from '@/domain/NPS'
import { ucfirst } from '@/utils/string'
import HistoricGraph from '@/mobile/src/components/HistoricGraph.vue'
import { getOverviewText } from '@/mobile/src/utils/feedback'
import { getFormattedScore } from '@/utils/scorecard'
import EmptyPageState from '@/mobile/src/components/EmptyPageState.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faWarning } from 'fontawesome/free-solid-svg-icons'
import { formatScore } from '@/utils/number'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'

@Component({
    methods: {
        isFiveScoreQuestionType,
        formatScore,
        getOverviewText,
        getFormattedScore,
    },
    components: {
        EmptyPageState,
        LoadingCard,
        Typography,
        Carousel,
        HistoricGraph,
        FontAwesomeIcon,
    },
    emits: ['refresh-finished', 'carousel-touch-start', 'carousel-touch-end'],
})
export default class TopOverview extends Vue {
    @Prop({ type: Boolean, default: false }) public shouldRefresh!: boolean

    // Force the loading card to render a loading state.
    @Prop({ type: Boolean, default: false }) public forceShowLoading?: boolean

    @Getter public $companyVars
    @Getter public mobileFilterActive
    @Getter public mobileQuestionType
    @Getter public npsSummary!: INPSSummary
    @Getter public npsStatsSummary?: INPSStatsSummary

    @Action public updateNpsSummary
    @Action public updateNpsStatsSummary

    private loading = false
    private faWarning = faWarning

    mounted() {
        if (!this.npsSummary || !this.npsSummary?.total) {
            this.onFilterChange()
        }
    }

    @Watch('hasNetworkConnection')
    @Watch('mobileQuestionType')
    public async onFilterChange() {
        this.loading = true

        // modules/dashboard.ts
        await this.updateNpsSummary(this.mobileQuestionType)
        await this.updateNpsStatsSummary(this.mobileQuestionType)
        this.loading = false
    }

    @Watch('shouldRefresh')
    public async forceRefresh() {
        if (this.shouldRefresh) {
            await this.onFilterChange()
            this.$emit('refresh-finished')
        }
    }

    public get npsChange(): number | boolean {
        if (Math.abs(this.npsSummary?.change) < 1) {
            return false
        }
        return this.npsSummary?.change
    }

    public get npsChangeIcon() {
        return Number(this.npsChange) >= 0 ? IconChangeUp : IconChangeDown
    }

    get metricLabel() {
        return (scoreType) => {
            const mappings =
                this.mobileQuestionType === 'nps' ? NPS_TYPE : FIVESCORE_TYPE
            return ucfirst(mappings[scoreType])
        }
    }

    public onCarouselTouchStart() {
        this.$emit('carousel-touch-start')
    }

    public onCarouselTouchEnd() {
        this.$emit('carousel-touch-end')
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/misc.less';

.feedback-top-overview-container {
    hr {
        margin: 0 -@featureGutter;
        .translucent-hr();
    }

    .feedback-top-overview {
        display: flex;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;

        .carousel-container {
            .an-carousel-dots {
                padding-top: @featureGutter;
                padding-bottom: @featureGutter;
            }
        }

        .content {
            width: 100%;
            border-radius: @borderRadius-md;
            padding: @featureGutterThick 0;
            text-align: center;
            color: @white;
            line-height: @featureGutter;

            .title {
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 10px;
                font-weight: 500;
            }

            .summary {
                .nps-big {
                    font-size: 60px;
                    font-weight: 300;
                    color: @white;
                    line-height: 67px;
                }
                .nps-change {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .trend-caret-medium {
                    width: 11px;
                    margin-left: 4px;
                    position: relative;
                    top: 0px;
                }

                .summary-score {
                    margin-top: 30px;
                }
            }

            .survey-stats,
            .responses-stats {
                .stat-line {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-bottom: 8px;

                    div {
                        flex: 1;
                        text-align: right;
                        opacity: 0.6;
                        font-weight: 500;

                        &:last-child {
                            text-align: left;
                            opacity: 1;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }
    }
}
#no-data {
    padding: @featureGutterThick @featureGutter;
    #error-icon {
        font-size: 42px;
    }
}
</style>
