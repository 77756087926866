<template>
    <div class="engagement-team-spotlight-user-container">
        <div class="header">
            <div class="left">
                <AvatarOrInitials :size="60" :user="userDetails" />
            </div>
            <div class="middle">
                <Typography variant="h4">{{ userData.name }}</Typography>
                <Typography variant="caption">
                    {{
                        formatScore(
                            userData.scorecard_score.value,
                            false,
                            false,
                            'nps',
                            $companyVars?.decimals
                        )
                    }}
                    {{ userData.scorecard_score.type }}</Typography>
            </div>
            <div class="right">
                <div class="score">
                    <Typography variant="h4">{{ scoreChange }}</Typography>
                    <Typography variant="caption score-up-down">
                        <UpDownArrow :positive="isScoreChangePositive" />
                    </Typography>
                </div>
                <Typography variant="caption" class="percent">
                    {{ Math.round(userData.previous_term_data.score) }}%
                    <FontAwesomeIcon
                        :icon="faArrowRight"
                        :class="{
                            red: !isScoreChangePositive,
                            green: isScoreChangePositive,
                        }"
                    />
                    <span class="off-black">
                        {{
                            Math.round(userData.current_term_data.score)
                        }}%</span>
                </Typography>
            </div>
        </div>
        <Typography variant="caption" class="last-session">
            <template v-if="userData.last_visited">
                last session: {{ relativeDate(userData.last_visited) }}
            </template>
            <template v-else> last session: Never </template>
        </Typography>

        <div :class="{ 'border-bottom': totalTopicCount > 0 }">
            <div class="data-header">
                <div class="title">Themes</div>
                <div id="engagement-trend-info" class="title">
                    Engagement
                    <img src="@/assets/img/Icons/info.svg" />
                </div>
            </div>
            <div
                v-for="(theme, themeIdx) in themeData"
                :key="themeIdx"
                class="data-row"
            >
                <Typography variant="body1">{{ theme.name }}</Typography>
                <div class="data-row-score">
                    <Typography variant="subtitle1">
                        {{
                            formatScore(
                                theme.current_term_data.score,
                                false,
                                false,
                                'nps',
                                $companyVars?.decimals
                            )
                        }}
                    </Typography>
                    <Typography variant="caption"><UpDownArrow
                            :positive="theme.change_in_term_data.score > 0"
                    /></Typography>
                </div>
            </div>
        </div>

        <template v-if="totalTopicCount > 0">
            <div class="data-header">
                <div class="title">Topics</div>
                <div class="title">NPS</div>
            </div>
            <!--@todo calculate NPS for each topic https://asknice.atlassian.net/browse/WEB-8062 -->
            <div v-if="getTopTopic" class="data-row">
                <Typography variant="body1">{{
                    getTopTopic.displayName
                }}</Typography>
                <Typography variant="body1">
                    {{
                        formatScore(
                            getTopTopic.nps,
                            false,
                            false,
                            'nps',
                            $companyVars?.decimals
                        )
                    }}
                    <slot><UpDownArrow :positive="getTopTopic.change > 0" /></slot>
                </Typography>
            </div>
            <div
                v-if="getWorstTopic && getTopTopic !== getWorstTopic"
                class="data-row"
            >
                <Typography variant="body1">{{
                    getWorstTopic.displayName
                }}</Typography>
                <Typography variant="body1">
                    {{
                        formatScore(
                            getWorstTopic.nps,
                            false,
                            false,
                            'nps',
                            $companyVars?.decimals
                        )
                    }}
                    <slot><UpDownArrow :positive="getWorstTopic.change > 0" /></slot>
                </Typography>
            </div>
        </template>

        <div class="button-container">
            <RainBaseButton
                variant="secondary"
                full
                @click="
                    router
                        .push({
                            name: 'scorecardHomeUser',
                            params: { teamMemberUserId: userData.id },
                        })
                        .catch((e) => e)
                "
            >
                View Scorecard
            </RainBaseButton>
        </div>
        <div class="button-container">
            <RainBaseButton variant="primary" full @click="onMessageUser">
                Message {{ userData.name }}
            </RainBaseButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import RainBaseButton from '@/components/Rain/Buttons/BaseButton.vue'
import { relativeDate } from '@/utils/time'
import { EngagementInsightsItem } from '@/entities/insights'
import { faArrowRight } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { Action, Getter } from 'vuex-facing-decorator'
import { useRouter } from 'vue-router'
import { formatScore } from '@/utils/number'

@Component({
    methods: { formatScore },
    components: {
        RainBaseButton,
        UpDownArrow,
        AvatarOrInitials,
        Typography,
        FontAwesomeIcon,
    },
})
export default class EngagementTeamSpotlightUser extends Vue {
    faArrowRight = faArrowRight
    relativeDate = relativeDate

    @Getter public $companyVars

    @Prop({ required: true, default: [] })
    userData!: EngagementInsightsItem

    @Getter
    $isMobile

    @Action
    setShowNoticesComposer

    private router = useRouter()

    public get userDetails() {
        return {
            id: this.userData.id,
            name: this.userData.name,
            avatar: this.userData.avatar,
        }
    }

    public get scoreChange() {
        return formatScore(
            this.userData.current_term_data.score -
                this.userData.previous_term_data.score,
            false,
            false,
            'nps',
            this.$companyVars?.decimals
        )
    }

    public get isScoreChangePositive() {
        return this.userData.change_in_term_data.score > 0
    }

    public get themeData() {
        return this.userData.recursive_data?.sort(
            (a, b) =>
                Math.abs(b.current_term_data.score) -
                Math.abs(a.current_term_data.score)
        )
    }

    public get totalTopicCount() {
        if (this.userData.scorecard_score) {
            return this.userData.scorecard_score.topics.length
        }

        return 0
    }

    public get getTopTopic() {
        if (this.totalTopicCount > 0 && this.userData.scorecard_score) {
            return this.userData.scorecard_score.topics.reduce((a, b) =>
                a.nps > b.nps ? a : b
            )
        }

        return null
    }

    public get getWorstTopic() {
        if (this.totalTopicCount > 1 && this.userData.scorecard_score) {
            return this.userData.scorecard_score.topics.reduce((a, b) =>
                a.nps < b.nps ? a : b
            )
        }

        return null
    }

    public onMessageUser() {
        if (this.$isMobile) {
            this.router.push({
                name: 'notices-pick-type-user',
                params: { preselectedUserId: this.userData.id },
            })
        } else {
            this.setShowNoticesComposer({ show: true })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/typography.less';

.engagement-team-spotlight-user-container {
    margin: @gutterSpacing-base;
    padding: @gutterSpacing-md;
    background: @white;
    border-radius: 8px;

    .header {
        display: flex;

        .middle {
            margin: 4px 12px;
            flex: 1;

            .caption {
                color: @grey;
                text-transform: uppercase;
            }
        }

        .right {
            text-align: right;
            margin: 4px 0;

            .score {
                display: flex;

                .h4 {
                    color: @offBlack;
                    margin-left: auto;
                }

                .caption {
                    color: @offBlack;
                    margin-top: 9px;
                }

                .score-up-down {
                    margin: 6px 0 0 4px;
                }
            }

            .caption {
                color: @grey;
            }

            .percent {
                margin-top: 2px;
                text-align: right;
                color: @grey;

                .red {
                    color: @errorRed;
                }

                .green {
                    color: @successGreen;
                }

                .off-black {
                    color: @offBlack;
                }
            }
        }
    }

    .last-session {
        padding: 6px 0 16px 0;
        text-align: right;
        color: @grey;
        border-bottom: 1px solid @outline;
    }

    .data-header {
        margin: 16px 0 12px 0;
        display: flex;
        justify-content: space-between;

        .title {
            .title();
            line-height: 16px;
            display: flex;
            align-items: center;
            img {
                margin-left: 4px;
            }
        }
    }

    .border-bottom {
        padding-bottom: 12px;
        border-bottom: 1px solid @outline;
    }

    .data-row {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;

        .data-row-score {
            display: flex;

            .caption {
                margin-top: 4px;
            }
        }
    }

    .button-container {
        margin-top: 12px;
    }
}
</style>
