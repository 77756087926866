<template>
    <div>
        <div class="rank-info">
            <div class="rank">
                <div class="number">
                    <span v-if="rankValues.length">
                        <img
                            data-v-5053a68c=""
                            src="@/assets/img/Icons/trophy.svg"
                            alt="trophy"
                        />
                        &nbsp;{{ rankValues.join(', ')
                        }}<span
                            v-if="rankValues.length === 1"
                            class="ordinal"
                            >{{ getOrdinalRank(rankValues[0]) }}</span>
                        <br />
                        <span class="ordinal">
                            in {{ scorecard.rankFieldLabel }}</span>
                    </span>
                    <span v-else> - </span>
                </div>
                <div class="last30">Last 30 Days</div>
            </div>
        </div>
        <div class="divider" />
        <div class="top-performers">
            <h3 class="top-performers-heading">Top Performers</h3>
            <div class="results">
                <ScorecardRankRow
                    v-for="(rank, index) in scorecardRanks"
                    :key="index"
                    :rank="rank"
                    :rank-num="index + 1"
                    :active="scorecard.names.indexOf(rank.name) >= 0"
                    :min-value="minValue"
                    :max-value="maxValue"
                    :company-nps="Number(companyNps)"
                    :is-last="index + 1 === scorecardRanks.length"
                    :avatar="getAvatar(rank)"
                    :is-five-score-question-type="
                        scorecard.isFiveScoreQuestionType
                    "
                />
            </div>
        </div>
        <div
            v-if="scorecard && scorecard.ranks.length > 3"
            class="show-more uk-text-center"
            @click="expanded = !expanded"
        >
            <span v-if="expanded">Collapse</span>
            <span v-else>Show more</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import { getOrdinal } from '@/utils/number'
import { TsNpsSummaryEntity } from '@/entities/teamscoreboard'
import fname from '@/utils/string'
import ScorecardRankRow from '@/components/Scorecard/ScorecardRankRow.vue'
import { ILeaderboardResult, INPSSummary } from '@/entities'

@Component({
    components: { ScorecardRankRow },
})
export default class ScorecardLeaderboard extends Vue {
    public fname = fname
    public expanded = false
    public topRanks: ILeaderboardResult[] = []

    @Getter public tsNpsSummary?: TsNpsSummaryEntity
    @Getter public scorecard!: ScorecardEntity
    @Getter public companyNpsSummary!: INPSSummary

    @Action public loadScorecard
    @Action public loadTsNpsSummary
    @Action public loadCompanyNpsSummary
    @Getter public $companyVars

    public mounted() {
        this.load()
    }

    public async load() {
        if (!this.scorecard) {
            await this.loadScorecard()
        }

        if (!this.companyNpsSummary.total) {
            await this.loadCompanyNpsSummary()
        }

        this.topRanks = this.scorecard.ranks.slice(0, 3)
    }

    public getAvatar(row: ILeaderboardResult) {
        return this.scorecard.avatars[row.name] || ''
    }

    public get minValue() {
        return Math.min(
            ...this.scorecard.ranks
                .filter((rank) => rank.responded >= 5)
                .map((rank) => rank.nps)
        )
    }

    public get maxValue() {
        return Math.max(
            ...this.scorecard.ranks
                .filter((rank) => rank.responded >= 5)
                .map((rank) => rank.nps)
        )
    }

    public getOrdinalRank(rank: string | number) {
        return getOrdinal(rank)
    }

    public get companyNps() {
        return Number(this.companyNpsSummary?.nps).toFixed(
            this.$companyVars?.decimals['nps'] ?? 1
        )
    }

    public get scorecardRanks() {
        if (this.expanded) {
            return this.scorecard.ranks
        } else {
            return this.topRanks
        }
    }

    public get rankValues() {
        if (!this.scorecard) {
            return []
        }
        return this.scorecard.rankValues.filter((rank) => rank > 0).slice(0, 6)
    }
}
</script>

<style scoped lang="less">
.rank {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    .ordinal {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
    }

    .last30 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #93a1be;
        margin-left: -1px;
        padding-top: 10px;
    }

    img {
        margin-top: -5px;
    }
}

.divider {
    width: 100%;
    border-bottom: 1px solid #e0e7ff;
    padding-top: 20px;
}

.top-performers {
    .top-performers-heading {
        padding-top: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.21px;
        text-transform: uppercase;
        color: #93a1be;
    }
}

.show-more {
    cursor: pointer;
    padding-top: 5px;
    color: #2e5bff;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
}
</style>
