import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-209b0a98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "filter" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "showing" }
const _hoisted_9 = { class: "body" }
const _hoisted_10 = {
  ref: "responses",
  class: "responses"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ScorecardBadgeHeading = _resolveComponent("ScorecardBadgeHeading")!
  const _component_DashboardResponsesList = _resolveComponent("DashboardResponsesList")!
  const _component_Intersectional = _resolveComponent("Intersectional")!
  const _component_Scrollable = _resolveComponent("Scrollable")!

  return (!/\/questions\/archived/.test(_ctx.route.fullPath) || _ctx.hasArchived)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
            'responses-container',
            { 'uk-width-small-1-1 uk-width-large-3-10': _ctx.isSideBar },
        ]),
        "data-test-tag": "dashboard-responses"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "responseLabel", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.responseLabel) + ": ", 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString((_ctx.unescapedTitle || 'NPS').toUpperCase() === 'NPS' &&
                        _ctx.isFiveScore
                            ? 'Rating'
                            : _ctx.unescapedTitle || 'NPS'), 1),
              _createElementVNode("div", {
                class: "router-link-container",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearFilter && _ctx.clearFilter(...args)))
              }, [
                (_ctx.currentPath === '/personalview')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      (_ctx.title !== '' && _ctx.title !== 'NPS')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.getClearFilterImage()
                          }, null, 8, _hoisted_5))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      (_ctx.title !== '' && _ctx.title !== 'NPS')
                        ? (_openBlock(), _createBlock(_component_RouterLink, {
                            key: 0,
                            tag: "img",
                            to: _ctx.clearFilterLink,
                            class: "clear-filter",
                            replace: ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: _ctx.getClearFilterImage(),
                                alt: "clear filter"
                              }, null, 8, _hoisted_7)
                            ], undefined, true),
                            _: 1
                          }, 8, ["to"]))
                        : _createCommentVNode("", true)
                    ]))
              ])
            ], true)
          ]),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.responsesMap[_ctx.key]
                        ? _ctx.formatNumber(_ctx.responsesMap[_ctx.key].length)
                        : 0) + " " + _toDisplayString(_ctx.responsesMap[_ctx.key] && _ctx.responsesMap[_ctx.key].length
                        ? ' of ' + _ctx.formatNumber(_ctx.responsesMap[_ctx.key][0].total)
                        : ''), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.showBadges && _ctx.hasSelectedPositiveBadge)
            ? (_openBlock(), _createBlock(_component_ScorecardBadgeHeading, {
                key: _ctx.scorecard.selectedBadge.value
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.tsMobileView)
              ? (_openBlock(), _createBlock(_component_Intersectional, {
                  key: 0,
                  "on-lower-scroll-end": _ctx.handleScrollEnd
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DashboardResponsesList, {
                      "display-key": _ctx.key,
                      loading: _ctx.loading.includes(_ctx.key),
                      "display-responses": _ctx.displayResponses,
                      type: _ctx.type,
                      "free-text": _ctx.freeText,
                      "is-five-score": _ctx.isFiveScore,
                      "show-badges": _ctx.showBadges,
                      "has-selected-badge": _ctx.hasSelectedBadge,
                      "results-depleted": _ctx.resultsDepleted
                    }, null, 8, ["display-key", "loading", "display-responses", "type", "free-text", "is-five-score", "show-badges", "has-selected-badge", "results-depleted"])
                  ], undefined, true),
                  _: 1
                }, 8, ["on-lower-scroll-end"]))
              : (_openBlock(), _createBlock(_component_Scrollable, {
                  key: 1,
                  "on-scroll-end": _ctx.handleScrollEnd,
                  style: _normalizeStyle(`max-height: calc(100vh - ${_ctx.calculateScrollHeight()}px); min-height: 600px;`)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DashboardResponsesList, {
                      "display-key": _ctx.key,
                      loading: _ctx.loading.includes(_ctx.key),
                      "display-responses": _ctx.displayResponses,
                      type: _ctx.type,
                      "free-text": _ctx.freeText,
                      "is-five-score": _ctx.isFiveScore,
                      "show-badges": _ctx.showBadges,
                      "has-selected-badge": _ctx.hasSelectedBadge,
                      "results-depleted": _ctx.resultsDepleted,
                      permissions: _ctx.permissions
                    }, null, 8, ["display-key", "loading", "display-responses", "type", "free-text", "is-five-score", "show-badges", "has-selected-badge", "results-depleted", "permissions"])
                  ], undefined, true),
                  _: 1
                }, 8, ["on-scroll-end", "style"]))
          ], 512)
        ])
      ], 2))
    : _createCommentVNode("", true)
}