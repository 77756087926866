<template>
    <div class="">
        <LoadingCard
            v-if="!hierarchyData || loading"
            :borderless="true"
            variant="single"
        />
        <div v-else class="teams-top-performer">
            <div v-if="pickedItem" class="content">
                <div>
                    <Typography variant="overline" class="hierarchy-title">
                        {{ title }}
                    </Typography>
                    <Typography
                        variant="h4"
                        component="h4"
                        class="hierarchy-name"
                    >
                        {{ pickedItem.name }}
                    </Typography>
                </div>
                <div>
                    <Typography variant="subtitle2" class="hierarchy-score">
                        <div>{{ scoreType }} {{ displayedScoreDisplay }}</div>
                        <div>
                            <img
                                v-if="
                                    Number(
                                        pickedItemScore
                                            ? pickedItemScore.change
                                            : 0
                                    ) > 0
                                "
                                src="@/assets/img/Icons/chevron-up-solid.svg"
                            />
                            <img
                                v-else
                                src="@/assets/img/Icons/chevron-down-solid.svg"
                            />
                            {{ displayedScoreChange }}
                        </div>
                    </Typography>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { IHierarchyGoal, IMonthScore } from '@/entities/reportcard'
import { Getter, namespace } from 'vuex-facing-decorator'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { getFormattedScore } from '@/utils/scorecard'
import { formatScore } from '@/utils/number'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'
const HierarchyLevelGoalsModule = namespace('hierarchylevelgoals')
const ScoredataModule = namespace('scoredata')

@Component({
    methods: { getFormattedScore },
    components: {
        LoadingCard,
        Typography,
    },
})
export default class TeamTopPerformer extends Vue {
    @Prop({ type: Boolean, default: false }) private loading?: boolean
    @Prop({ type: String }) private title!: string
    @Prop({ type: String }) private type?: string
    @Getter public $companyVars

    public pickedItem: IHierarchyGoal | null = null
    public pickedItemScore: IMonthScore | null = null
    private type_top_performer = 'top-performer'
    private hasDisplayCsatAsPercentage

    @HierarchyLevelGoalsModule.Getter hierarchyData!: IHierarchyGoal[]
    @ScoredataModule.Getter scoreType

    mounted() {
        this.dataUpdated()
        this.hasDisplayCsatAsPercentage =
            this.$companyVars.has_display_csat_as_percentage
    }

    @Watch('hierarchyData')
    private async dataUpdated() {
        if (this.hierarchyData) {
            if (this.type === this.type_top_performer) {
                // For Top Performer output.
                this.pickedItem = this.hierarchyData
                    ? this.hierarchyData[0]
                    : null
            } else {
                // For Most Improved component output.
                for (let i = 0; i < this.hierarchyData.length; i++) {
                    let length = this.hierarchyData[i]?.score.length - 1
                    if (i > 0) {
                        // Comparing the Change value, to pick up the highest changed item.
                        // Note: different object may have different number of score records.
                        if (
                            Number(
                                this.pickedItem?.score[
                                    this.pickedItem?.score.length - 1
                                ].change
                            ) <
                            Number(this.hierarchyData[i].score[length].change)
                        ) {
                            this.pickedItem = this.hierarchyData[i]
                        }
                    } else {
                        // The first time.
                        this.pickedItem = this.hierarchyData[i]
                    }
                }
            }
            if (this.pickedItem) {
                this.pickedItemScore =
                    this.pickedItem?.score[this.pickedItem?.score.length - 1]
            }
        }
    }

    get displayedScoreDisplay() {
        return formatScore(
            this.pickedItemScore ? this.pickedItemScore.metricScore ?? 0 : 0,
            isFiveScoreQuestionType(this.scoreType),
            this.hasDisplayCsatAsPercentage,
            this.scoreType,
            this.$companyVars?.decimals
        )
    }

    get displayedScoreChange() {
        return formatScore(
            Number(this.pickedItemScore ? this.pickedItemScore.change ?? 0 : 0),
            isFiveScoreQuestionType(this.scoreType),
            this.hasDisplayCsatAsPercentage,
            this.scoreType,
            this.$companyVars?.decimals
        )
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.teams-top-performer {
    display: flex;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;

    .content {
        display: flex;
        width: 100%;
        border-radius: 8px;
        padding: 15px 15px;
        background-color: rgba(255, 255, 255, 0.1);
        justify-content: space-between;

        .hierarchy-title,
        .hierarchy-name,
        .hierarchy-score {
            color: white;
        }
        .hierarchy-score {
            text-align: right;
            text-transform: uppercase;
        }
    }
}
</style>
