import { LooseObject } from '@/pages/appstore/entities/app'
import type { Ref } from 'vue'

export enum UserRoleType {
    FrontlineStaff = 'frontline_staff',
    FrontlineManager = 'frontline_manager',
    HeadOffice = 'head_office',
    Admin = 'Admin',
}

export interface UserDetails {
    permissions: string[]
    company: LooseObject
    userRole: string
    userRoleType: UserRoleType
    userMasterRole?: string
    userMasterRoleType?: UserRoleType
    hasPushDevice: number
    userHasMlp: boolean
    userHasMfa?: boolean | null
    selectedScorecard: number | null
}

export interface LoginToken {
    token: string
    name: string
    company: string
}

export interface MultiLoginTokens {
    [domain: string]: LoginToken
}

export interface MultiLoginTokenResult {
    success: boolean
    email?: string
    activationLinks?: MultiLoginTokens
    msg?: string
    token?: {
        user?: string
    }
}

export interface LoginPayload {
    email: string
    password: string
    mfa?: string
    mfaRememberDevice?: boolean
    deviceId?: string
    mfaSetup?: string
    recoveryCode?: string
}

export interface GoogleAuthResult {
    id: string
    name: string
    familyName: string
    givenName: string
    imageUrl: string
    email: string
    serverAuthCode: string

    authentication: {
        accessToken: string
        refreshToken: string
        idToken: string
    }
}

export interface GoogleSignInResult {
    success: boolean
    googleUserAuthId?: string
    msg?: string
}

export enum Permissions {
    RoleUserBasics = 'ROLE_USER_BASICS',
    RoleViewNameEmailCustomData = 'ROLE_VIEW_NAME_AND_EMAIL',
    RoleViewNotes = 'ROLE_VIEW_NOTES',
    RoleRunWorkflows = 'ROLE_RUN_WORKFLOWS',
    RoleViewWorkflows = 'ROLE_VIEW_WORKFLOWS',
    RoleEditWorkflows = 'ROLE_EDIT_WORKFLOWS',
    RoleAdminBasics = 'ROLE_ADMIN_BASICS',
}

export const LoginMainPkey = 'LOGINMAIN_PKEY'

export type LoginMainProvided = {
    credentials: Ref<Credentials>
    persistCredentials: Function
    resetCredentials: Function
}

export interface Credentials {
    email?: string
    password?: string
}
