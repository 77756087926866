<template>
    <div>
        <div class="topic-group">
            <FontAwesomeIcon
                :icon="faArrowCircleRight"
                size="xs"
                class="group-arrow-icon"
            />
            <Typography variant="subtitle2" class="group-text">
                {{ group }}
            </Typography>
        </div>
        <div style="display: flex; justify-content: space-between">
            <div class="nps-bar">
                <div class="nb-bar nb-background"></div>
                <div
                    class="nb-bar nb-nps"
                    :style="{
                        left: deltaPositionRelative + '%',
                        width: deltaWidthPercent + '%',
                        background: npsColor,
                        opacity: 0.9,
                        borderRadius: borderRadius,
                    }"
                />
                <div
                    v-if="showGoal"
                    class="goal-container"
                    :style="{ left: middlePercent + '%' }"
                >
                    <div class="goal">
                        <Typography variant="caption" class="goal-text">Goal {{ getGoal }}</Typography>
                    </div>
                    <div class="nb-average"></div>
                </div>
            </div>
            <div class="number-part">
                <Typography
                    variant="subtitle2"
                    :class="[
                        nps < getGoal ? 'warning-orange' : 'success-green',
                    ]"
                >
                    <div style="display: flex; justify-content: flex-end">
                        <span class="nps-number">{{
                            formatScore(
                                nps,
                                isFiveScoreQuestionType(scoreType),
                                $companyVars.has_display_csat_as_percentage,
                                scoreType,
                                $companyVars?.decimals
                            )
                        }}</span>
                        <img
                            v-if="nps >= previousNps"
                            src="@/assets/img/Icons/chevron-up-solid.svg"
                            style="transform: translateY(-2px)"
                        />
                        <img
                            v-else
                            src="@/assets/img/Icons/chevron-down-solid.svg"
                            class="error-red"
                        />
                    </div>
                </Typography>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faArrowCircleRight } from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { formatScore } from '@/utils/number'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'

@Component({
    methods: { isFiveScoreQuestionType, formatScore },
    components: {
        Typography,
        FontAwesomeIcon,
    },
})
export default class NPSLineGraph extends Vue {
    @Prop({ type: [String], required: true }) public group!: string
    @Prop({ type: Number, required: true }) public nps!: number
    @Prop({ type: String, default: 'nps' }) public scoreType!: string
    @Prop({ type: Number, required: true }) public previousNps!: number
    @Prop({ type: Number, required: true }) public min!: number
    @Prop({ type: Number, required: true }) public max!: number
    @Prop({ type: Number, required: true }) public average!: number
    @Prop({ type: Boolean, required: false, default: false }) public showGoal

    @Getter public $companyVars

    private goalIndicatorPosition = 50
    private adjustGoalLinePosition = 30
    private adjustGoalIndicatorPosition = 15

    public faArrowCircleRight = faArrowCircleRight

    public get range(): number {
        return this.max - this.min
    }

    public get middleRelative() {
        return this.average - this.min
    }

    public get middlePercent(): number {
        const value =
            this.range > 0 ? (this.middleRelative / this.range) * 100 : 50
        let position = Math.max(0, Math.min(100, value))
        let moveRight = this.adjustGoalLinePosition
        // Avoid Goal indicator overlapped on topic text.
        position = position < 37 ? position + moveRight : position
        // move Goal indicator to center the average line
        this.goalIndicatorPosition = position + this.adjustGoalIndicatorPosition
        return position
    }

    public get deltaPercent() {
        return this.nps - this.getGoal
    }

    public get deltaWidthPercent() {
        const value = Math.abs(this.deltaPercent)

        // the bar cannot be wider than the space it occupies.
        let widthLimit
        if (this.nps >= this.getGoal) {
            widthLimit = 100 - this.deltaPositionRelative
        } else {
            widthLimit = this.middlePercent
        }

        return Math.max(0, Math.min(value, widthLimit))
    }

    /**
     * deltaPosition - from Goal line position
     * deltaPercent - positive, on the right of Goal line
     */
    public get deltaPositionRelative() {
        let value
        if (this.deltaPercent < 0) {
            value = this.middlePercent - this.deltaWidthPercent
        } else {
            value = this.middlePercent
        }
        return value
    }

    public get npsColor() {
        if (this.nps > this.getGoal) {
            return '#00D68F'
        } else {
            return '#FFAA00'
        }
    }

    public get borderRadius() {
        if (this.nps > this.getGoal) {
            return '0 100px 100px 0'
        } else {
            return '100px 0 0 100px'
        }
    }

    public get getGoal() {
        switch (this.scoreType) {
            case 'csat':
                if (this.$companyVars.has_display_csat_as_percentage == '1') {
                    return 100 * (this.$companyVars.target_csat / 5)
                }
                return this.$companyVars.target_csat
            case 'fivestar':
                return this.$companyVars.target_fivestar
            case 'nps':
                return this.$companyVars.target
        }
        return null
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.success-green {
    color: @successGreen;
}

.warning-orange {
    color: @warningOrange;
}

.error-red {
    color: @errorRed;
}

.success-green-as-background {
    background: @successGreen;
}

.warning-orange-as-background {
    background: @warningOrange;
}

.nps-bar {
    height: calc(
        100% + 4px
    ); // Height to allow for NPS average bar + 4px to accomodate table borders
    width: 100%;
    position: relative;

    .nb-bar {
        width: 100%;
        height: 6px;
        border-radius: 2px;

        position: absolute;
        top: 50%; // Vertically center the NPS bar
        transform: translateY(-50%);
        background-color: #ffffff;
        opacity: 0.2;
    }

    .nb-average {
        background: #ffffff;
        opacity: 0.2;
        width: 2px;
        height: 300px;
        display: block;
    }

    @media print {
        -webkit-print-color-adjust: exact !important;
    }
}

.number-part {
    min-width: 40px;
    height: 10px;
    padding-left: 10px;
    transform: translateY(-10px);
    vertical-align: middle;

    .nps-number {
        margin-right: 5px;
        line-height: 20px;
    }
}

.goal-container {
    min-width: 40px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: -30px;
    color: #ffffff;
    opacity: 0.4;
    @media (max-width: 400px) {
        top: -50px;
    }
}

.goal {
    display: inline-block;
    border: 1px solid #ffffff;
    border-radius: 100px;
    transform: translateX(-50%);
    padding: 4px 6px;
}

.group-text {
    color: white;
    line-height: 20px;
}

.topic-group {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 10px;
}

.goal-text {
    color: #ffffff;
    line-height: 8px;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
</style>
