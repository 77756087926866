// format number to like, 123,456.78
export function formatNumber(number, fixed: number | undefined = undefined) {
    return Number(number)
        .toFixed(fixed)
        .replace(/\d(?=(\d{3})+(\.\d+)?$)/g, '$&,')
}

export function formatScore(
    number: string | number,
    fiveScore?: boolean,
    percentage?: boolean,
    scoreType = 'nps',
    decimals: {
        nps: number
        csat: number
        fivestar: number
        ces: number
    } = {
        nps: 0,
        csat: 2,
        fivestar: 2,
        ces: 1,
    }
): string {
    const num = Number(number)
    const normalizedScoreType = scoreType.toLowerCase() as
        | 'nps'
        | 'csat'
        | 'fivestar'
        | 'ces'
    if (Math.abs(num) >= 100) {
        return num.toFixed(0)
    }

    // Set defaults if not provided
    if (fiveScore === undefined) {
        fiveScore = false
    }
    if (percentage === undefined) {
        percentage = false
    }

    // If decimals and scoreType are provided, use the new logic
    if (decimals && normalizedScoreType) {
        if (normalizedScoreType === 'csat' && percentage) {
            return num.toFixed(1)
        }

        return num.toFixed(decimals[normalizedScoreType])
    }

    // Fallback to original logic
    return num.toFixed(fiveScore && !percentage ? 2 : 1)
}

export function getOrdinal(n) {
    if (!n) {
        return ''
    }
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return s[(v - 20) % 10] || s[v] || s[0]
}

export function getPercentageString(count, total): string {
    if (total === 0) {
        return '0%'
    }
    return Math.round((count / total) * 100) + '%'
}

export function countDecimals(value) {
    if (!value) return 0

    if (Math.floor(value.valueOf()) === value.valueOf()) return 0

    const str = Math.abs(value).toString()
    if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
        return str.split('-')[1] || 0
    } else if (str.indexOf('.') !== -1) {
        return str.split('.')[1].length || 0
    }
    return str.split('-')[1] || 0
}
