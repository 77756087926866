<template>
    <div class="table-container">
        <div v-if="!isRoot" class="header">
            <div class="non-root" @click="$emit('back')">
                <img
                    src="@/assets/img/Icons/corner-right-up.svg"
                    alt="Up one level"
                    width="16"
                    height="16"
                />
                Back to {{ name }}
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th class="table-header left-align">
                        {{ viewByGroupTypeName }}
                    </th>
                    <th class="table-header"></th>
                    <th
                        class="table-header right-align"
                        @click="toggleSortDirection"
                    >
                        {{ metrics }}
                        <SortDirection :direction="sortDirection" />
                    </th>
                </tr>
            </thead>
            <tbody class="body">
                <tr v-if="isLoadingInsightsData">
                    <td class="table-cell" colspan="3">
                        <div class="loading">
                            <Loading inline transparent loading />
                        </div>
                    </td>
                </tr>
                <template v-else>
                    <tr v-if="rows.length === 0">
                        <td class="table-cell" colspan="3">
                            <div class="heading">
                                No
                                {{
                                    pluralizedViewByGroupTypeName.toLowerCase()
                                }}
                                match your filters
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="row in rows"
                        v-else
                        :key="row.group"
                        @click="onClickRow(row)"
                    >
                        <td class="table-cell">
                            <div class="value-container">
                                <!-- is leaf, show avatar -->
                                <img
                                    v-if="isLeaf"
                                    class=""
                                    src="@/assets/img/Icons/Grey/Contact.svg"
                                    alt="avatar"
                                />
                                <!-- not leaf, show arrow -->
                                <img
                                    v-else
                                    class=""
                                    src="@/assets/img/Icons/Grey/Forward.svg"
                                    alt="group"
                                />
                                <span class="value">{{ row.group }}</span>
                            </div>
                        </td>
                        <td class="table-cell">
                            <img
                                v-if="isGoldStatus(row.status)"
                                src="@/assets/img/Icons/round_star_gold.svg"
                                alt="gold"
                            />
                            <img
                                v-else-if="isSilverStatus(row.status)"
                                src="@/assets/img/Icons/round_star_silver.svg"
                                alt="silver"
                            />
                            <img
                                v-else-if="isBronzeStatus(row.status)"
                                src="@/assets/img/Icons/round_star_bronze.svg"
                                alt="bronze"
                            />
                        </td>
                        <td class="table-cell">
                            <div class="nps-container">
                                {{ rowValue(row).formattedValue }}
                                <UpDownArrow
                                    class="arrow"
                                    :positive="rowValue(row).delta >= 0"
                                />
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import BaseCard from '@/components/BaseCard.vue'
import {
    TsGroupEntity,
    TsGroupRepeatVisitsEntity,
} from '@/entities/teamscoreboard'
import UpDownArrow from '@/components/UpDownArrow.vue'
import {
    GroupStatusMap,
    InsightsMetric as InsightsMetrics,
    InsightsStatus,
    SelectedStatuses,
} from '@/mobile/src/types/insights'
import Loading from '@/components/Loading.vue'
import SortDirection from '@/components/SortDirection.vue'
import { friendlyCustomerTenure } from '@/mobile/src/utils/insights'
import { formatScore } from '@/utils/number'

@Component({
    components: {
        UpDownArrow,
        BaseCard,
        Loading,
        SortDirection,
    },
    emits: ['back', 'drilldown'],
})
export default class InsightsTable extends Vue {
    // whether showing leaf level, decides the img
    @Prop({ type: Boolean, default: false })
    readonly isLeaf!: boolean

    @Prop({ type: Boolean, default: true })
    readonly isRoot!: boolean

    // current hierarchy field name
    @Prop({ type: String, default: '' })
    readonly viewByGroupTypeName!: string

    @Prop({ type: String, default: '' })
    readonly pluralizedViewByGroupTypeName!: string

    @Prop({ type: Array, required: true, default: () => [] })
    readonly teamScoreboardGroups!: TsGroupEntity[]

    @Prop({ type: Array, required: true, default: () => [] })
    readonly repeatVisitsGroups!: TsGroupRepeatVisitsEntity[]

    @Prop({ type: Object, default: () => ({}) })
    readonly statuses!: GroupStatusMap

    @Prop({ type: String, required: true, default: InsightsMetrics.NPS })
    readonly selectedMetric!: InsightsMetrics

    @Prop({ type: Object, required: true, default: () => ({}) })
    readonly selectedStatuses!: SelectedStatuses

    @Prop({ type: Boolean, default: false })
    readonly isLoadingInsightsData!: boolean

    @Prop({ type: String })
    readonly previousGroupName!: string

    @Prop({ type: String })
    readonly name!: string

    @Getter readonly $companyVars

    sortDirection: 'asc' | 'desc' = 'desc'

    get metrics() {
        const map = {
            [InsightsMetrics.REPEAT_VISIT]: 'Repeat %',
            [InsightsMetrics.NPS]: 'NPS',
            [InsightsMetrics.CSAT]: 'CSAT',
            [InsightsMetrics.FIVESTAR]: '5☆',
            [InsightsMetrics.RETENTION]: 'Retention %',
            [InsightsMetrics.TENURE]: 'Tenure',
        }

        return map[this.selectedMetric]
    }

    get rows() {
        const filteredGroups = Object.values(this.selectedStatuses).some(
            (status) => status
        )
            ? this.teamScoreboardGroups.filter((group) => {
                  return this.selectedStatuses[this.statuses[group.group]]
              })
            : this.teamScoreboardGroups

        const rows = filteredGroups.map((group) => {
            return {
                group: group.group,
                avatar: group.avatar,
                user_id: group.user_id,
                status: this.statuses[group.group],
                value: group.NPS,
                formattedValue: `${group.NPS}`,
                delta: group.scoreDelta,
            }
        })

        rows.sort((a, b) => {
            // convert to number first in case of string
            const valueA = Number(this.rowValue(a).value)
            const valueB = Number(this.rowValue(b).value)
            if (valueA === valueB) {
                return 0
            }

            if (this.sortDirection === 'asc') {
                return valueA > valueB ? 1 : -1
            }

            return valueA < valueB ? 1 : -1
        })

        return rows
    }

    rowValue(group) {
        const groupRepeatVisits = this.repeatVisitsGroups.find(
            (g) => g.group === group.group
        )

        if (
            groupRepeatVisits &&
            this.selectedMetric === InsightsMetrics.REPEAT_VISIT
        ) {
            return {
                value: groupRepeatVisits.repeatVisitPercentage,
                formattedValue: `${groupRepeatVisits.repeatVisitPercentage.toFixed(
                    1
                )}%`,
                delta: groupRepeatVisits.repeatVisitDelta,
            }
        }

        if (
            groupRepeatVisits &&
            this.selectedMetric === InsightsMetrics.RETENTION
        ) {
            return {
                value: groupRepeatVisits.retention.retention,
                formattedValue: `${groupRepeatVisits.retention.retention.toFixed(
                    1
                )}%`,
                delta:
                    groupRepeatVisits.retention.retention -
                    groupRepeatVisits.previousRetention.retention,
            }
        }

        if (
            groupRepeatVisits &&
            this.selectedMetric === InsightsMetrics.TENURE
        ) {
            return {
                value: groupRepeatVisits.retention.retention,
                formattedValue: `${friendlyCustomerTenure(
                    groupRepeatVisits.tenure.tenure
                )}`,
                delta:
                    groupRepeatVisits.tenure.tenure -
                    groupRepeatVisits.previousTenure.tenure,
            }
        }

        if (this.selectedMetric === InsightsMetrics.CSAT) {
            let formattedValue = formatScore(
                group.formattedValue,
                true,
                this.$companyVars.has_display_csat_as_percentage,
                'fivestar',
                this.$companyVars?.decimals
            )

            if (this.$companyVars.has_display_csat_as_percentage) {
                formattedValue += '%'
            }

            return {
                value: group.value,
                formattedValue: formattedValue,
                delta: group.delta,
            }
        } else if (this.selectedMetric === InsightsMetrics.FIVESTAR) {
            return {
                value: group.value,
                formattedValue: formatScore(
                    group.formattedValue,
                    true,
                    false,
                    'fivestar',
                    this.$companyVars?.decimals
                ),
                delta: group.delta,
            }
        }

        return {
            value: group.value,
            formattedValue: group.formattedValue,
            delta: group.delta,
        }
    }

    isGoldStatus(status: InsightsStatus) {
        return status === InsightsStatus.GOLD
    }

    isSilverStatus(status: InsightsStatus) {
        return status === InsightsStatus.SILVER
    }
    isBronzeStatus(status: InsightsStatus) {
        return status === InsightsStatus.BRONZE
    }

    onClickRow(row) {
        // drill down a level
        this.$emit('drilldown', row)
    }

    toggleSortDirection() {
        if (this.sortDirection === 'asc') {
            this.sortDirection = 'desc'
        } else {
            this.sortDirection = 'asc'
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/variables';

.header {
    padding-left: 10px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: @gutterSpacing 0;
}

.non-root {
    color: @blue;
    display: flex;
    align-items: center;
}

.table {
    width: 100%;
}

.body {
    color: #2e384d;
    line-height: 20px;
    font-size: 14px;
}

.loading {
    display: flex;
    justify-content: center;
}

.heading {
    margin: @gutterSpacing * 2 0;
    text-align: center;
}

.table-header {
    .title();
    height: 40px;

    &:first-child {
        padding-left: 10px;
    }

    &:last-child {
        padding-right: 10px;
    }

    &.left-align {
        text-align: left;
    }

    &.right-align {
        text-align: right;
    }
}

.table-cell {
    height: 40px;

    &:first-child {
        padding-left: 10px;
    }

    &:last-child {
        padding-right: 10px;
    }
}

.value-container,
.nps-container {
    display: flex;
    align-items: center;
}

.value-container {
    text-align: left;
    font-weight: 500;
}

.value {
    margin-left: 5px;
}

.nps-container {
    text-align: right;
    justify-content: flex-end;
}

.arrow {
    margin-left: 5px;
}
</style>
