import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { LoadState } from '@/common/interfaces/loadstate'
import { loadAuthState } from '@/api/auth'
import { UserDetails, UserRoleType } from '@/mobile/src/types/auth'
import { clearAllFromSessionStorage } from '@/utils/sessionstorage'
import { saveSelectedScorecard as updateSelectedScorecard } from '@/api/user'

export interface AuthState {
    roles: {
        [role: string]: boolean
    }
    user: {
        name: string
        firstName: string
    }
    activeMetrics: []
    loadState: LoadState
    details: UserDetails
}

export const state: AuthState = {
    roles: {},
    user: {
        name: '',
        firstName: '',
    },
    activeMetrics: [],
    loadState: LoadState.UNSET,
    details: {
        permissions: [],
        company: {},
        userRole: '',
        userRoleType: UserRoleType.FrontlineStaff,
        hasPushDevice: 0,
        userHasMlp: false,
        selectedScorecard: null,
    },
}

const getters: GetterTree<AuthState, any> = {
    getRoles: ({ roles }) => roles,
    getUser: ({ user }) => user,
    getActiveMetrics: ({ activeMetrics }) => activeMetrics,
    details({ details }) {
        return details
    },
    isFrontlineEngagementUser({ details }) {
        const { company, userRoleType }: UserDetails = details
        return (
            company.has_insights_v3 &&
            userRoleType === UserRoleType.FrontlineStaff
        )
    },
    getSelectedScorecard({ details }) {
        return details.selectedScorecard
    },
}

const actions: ActionTree<AuthState, any> = {
    async loadAuthState({ commit, state: { loadState } }) {
        if (loadState === LoadState.LOADING) {
            return
        }
        commit('setLoadState', LoadState.LOADING)

        try {
            clearAllFromSessionStorage()
            const {
                permissions,
                company,
                user,
                activeMetrics,
                userRole,
                userRoleType,
                hasPushDevice,
                userHasMlp,
                selectedScorecard,
            } = await loadAuthState()
            commit('setRoles', permissions)
            commit('setUser', user)
            commit('setDetails', {
                permissions,
                company,
                userRole,
                userRoleType,
                hasPushDevice,
                userHasMlp,
                selectedScorecard,
            })
            commit('setActiveMetrics', activeMetrics)
        } catch (e) {
            commit('setLoadState', LoadState.ERROR)
        }

        commit('setLoadState', LoadState.LOADED)
    },

    saveSelectedScorecard({ commit }, scorecard) {
        updateSelectedScorecard(scorecard)
        commit('setSelectedScorecard', scorecard.id)
    },
}

const mutations: MutationTree<AuthState> = {
    setLoadState(state, data) {
        state.loadState = data
    },
    setRoles(state, data: string[]) {
        state.roles = data.reduce((roles, role) => {
            roles[role] = true
            return roles
        }, {})
    },
    setUser(state, data: any) {
        state.user = {
            ...data,
        }
    },
    setActiveMetrics(state, data: any) {
        state.activeMetrics = {
            ...data,
        }
    },
    setDetails(state, details: UserDetails) {
        state.details = details
    },
    setSelectedScorecard(state, scorecard) {
        state.details.selectedScorecard = scorecard
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
