<template>
    <div
        :class="['feedback-actions-bar', { 'show-border': actions.length > 0 }]"
    >
        <div
            v-for="(action, actionIdx) in actions"
            :key="actionIdx"
            class="action-container"
        >
            <div class="action" @click="action.onClick">
                <FontAwesomeIcon :icon="action.icon" />
                <div v-if="action.loading" class="loading-wave" />
                <Typography v-else variant="overline" class="action-text">{{
                    action.label
                }}</Typography>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faClock,
    faWrench,
    faUser,
    faHeadset,
    IconDefinition,
} from 'fontawesome/free-solid-svg-icons'
import { timeSince } from '@/utils/time'
import { pendoTrackEvent } from '@/utils/pendo'
import { toggleCaseClosed } from '@/api/chat'
import { Permissions } from '@/mobile/src/types/auth'
import ActionButton from '@/mobile/src/components/appV4/ActionButton.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import LoadingCard from '@/mobile/src/components/appV4/LoadingCard.vue'
import { useRouter } from 'vue-router'

interface FeedbackAction {
    show: boolean
    loading: boolean
    label: string
    icon: IconDefinition
    onClick: Function
}

enum CaseStatus {
    Default = 'default',
    Open = 'open',
    Close = 'close',
}

@Component({
    components: {
        LoadingCard,
        ActionButton,
        FontAwesomeIcon,
        Typography,
    },
})
export default class FeedbackActionsBar extends Vue {
    @Getter public chatCustomFields
    @Getter public chatCustomData
    @Getter private chatQuestionLoading
    @Action private reloadChatCustomData
    @Action public setChatQuestion!: (id: number) => void
    @Getter public permissions
    @Getter public $companyVars

    @Prop({ type: Number, required: true }) feedbackId!: number

    private caseStatusLoading = false

    private router = useRouter()

    public get actions(): FeedbackAction[] {
        return [
            {
                show: this.hasPermission(Permissions.RoleUserBasics),
                loading: this.caseStatusLoading || this.chatQuestionLoading,
                label: this.labelCaseManagement,
                icon: faClock,
                onClick: () => this.goCaseManagement(),
            },
            {
                show:
                    this.hasPermission(Permissions.RoleUserBasics) &&
                    this.$companyVars?.has_advanced_case_management === 1 &&
                    (this.getCaseStatus() == CaseStatus.Open ||
                        (this.getCaseStatus() == CaseStatus.Close &&
                            this.chatCustomData?.case_assigned_user_id !==
                                null)),
                loading: this.caseStatusLoading || this.chatQuestionLoading,
                label: this.labelCaseAssignment,
                icon: faHeadset,
                onClick: () => this.goTriggerCaseAssignment(),
            },
            {
                show: this.hasPermission(Permissions.RoleRunWorkflows),
                loading: this.chatQuestionLoading,
                label: 'Workflow',
                icon: faWrench,
                onClick: () => this.goTriggerWorkflow(),
            },
            {
                show: this.hasPermission(
                    Permissions.RoleViewNameEmailCustomData
                ),
                loading: this.chatQuestionLoading,
                label: this.labelCustomData,
                icon: faUser,
                onClick: () => this.goCustomData(),
            },
        ].filter((action) => action.show)
    }

    async mounted(): Promise<void> {
        if (
            Object.keys(this.chatCustomData).length === 0 &&
            this.chatCustomData.constructor === Object
        ) {
            await this.setChatQuestion(this.feedbackId)
        }
    }

    private get labelCaseManagement(): string {
        const since = timeSince(this.chatCustomData.case_opened_time)

        switch (this.getCaseStatus()) {
            case CaseStatus.Open:
                if (since === 'now') {
                    return 'Case opened just now'
                } else {
                    return since.replace(/(\d+)/, `Case opened $1`)
                }
            case CaseStatus.Close:
                return 'Closed via ' + this.chatCustomData.case_closed_option
            default:
                return 'Open Case'
        }
    }

    private get labelCustomData(): string {
        return `Custom data (${this.chatCustomFields?.length ?? 0})`
    }

    private getCaseStatus(): CaseStatus {
        if (!this.chatCustomData || !this.chatCustomData.case_closed) {
            return CaseStatus.Default
        } else if (Number(this.chatCustomData.case_closed) === 1) {
            return CaseStatus.Close
        }
        return CaseStatus.Open
    }

    private async openCase(): Promise<void> {
        if (!this.caseStatusLoading || !this.chatQuestionLoading) {
            this.caseStatusLoading = true
            const { data } = await toggleCaseClosed(
                this.feedbackId,
                false,
                null
            )
            if (data.success) {
                this.$emit('case-updated')
                await this.reloadChatCustomData()
            }
            this.caseStatusLoading = false
        }
    }

    private async goCaseManagement(): Promise<void> {
        if (this.caseStatusLoading || this.chatQuestionLoading) {
            return
        }

        switch (this.getCaseStatus()) {
            case CaseStatus.Open:
            case CaseStatus.Close:
                await this.router.push({
                    name: 'feedbackcasemanagement',
                    params: {
                        feedbackid: this.feedbackId.toString(),
                    },
                })

                break
            default:
                await this.openCase()
        }

        pendoTrackEvent('mobile-feedback-case-management-menu-opened')
    }

    private goTriggerWorkflow(): void {
        if (!this.chatQuestionLoading) {
            this.router.push({
                name: 'feedbackworkflow',
                params: {
                    feedbackid: this.feedbackId.toString(),
                    isanomymous: this.chatCustomData.anom ?? 0,
                },
            })
            pendoTrackEvent('mobile-feedback-workflow-menu-opened')
        }
    }

    private goTriggerCaseAssignment(): void {
        if (
            !this.chatQuestionLoading &&
            this.getCaseStatus() != CaseStatus.Close
        ) {
            this.router.push({
                name: 'feedbackcaseassignment',
                params: {
                    feedbackid: this.feedbackId.toString(),
                },
            })
            pendoTrackEvent('mobile-feedback-case-assign-to-menu-opened')
        }
    }

    private goCustomData(): void {
        if (!this.chatQuestionLoading) {
            this.router.push({
                name: 'feedbackcustomdata',
                params: {
                    feedbackid: this.feedbackId.toString(),
                },
            })
            pendoTrackEvent('mobile-feedback-custom-data-viewed')
        }
    }

    private hasPermission(permission): boolean {
        return this.permissions?.includes(permission)
    }

    private get labelCaseAssignment(): string {
        if (this.chatCustomData?.case_assigned_user_id) {
            return this.chatCustomData?.case_assigned_user_name
        }

        return 'Assign Case'
    }
}
</script>

<style lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';
@import '~@/mobile/src/styles/misc.less';
@import '~@/mobile/src/styles/animations.less';

.feedback-actions-bar {
    display: flex;
    gap: 30px;
    width: calc(100% + @featureGutter * 2);
    position: relative;
    right: @featureGutter;
    .horizontal-scroll-container();

    // Only show the border if there are actions present
    &.show-border {
        border-bottom: 1px solid @thinFog;
    }
}

.action-container {
    &:first-of-type {
        padding-left: @featureGutter;
    }
    &:last-of-type {
        padding-right: @featureGutter;
    }
}

.action {
    color: @white;
    display: flex;
    gap: @gutterSpacing-base;
    white-space: nowrap;
    margin: @gutterSpacing-md 0;
    align-items: center;

    .action-text {
        color: @white;
    }
}

.loading-wave {
    width: 100px;
    height: 18px;
    border-radius: @borderRadius-md;
    .loading-wave();
}
</style>
