import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4394b0f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "feedback-workflow-container"
}
const _hoisted_2 = { class: "feedback-workflow-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingCard = _resolveComponent("LoadingCard")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loadingWorkflow)
      ? (_openBlock(), _createBlock(_component_LoadingCard, {
          key: 0,
          variant: "single",
          borderless: ""
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredWorkflows, (chatWorkflow) => {
              return (_openBlock(), _createBlock(_component_ActionButton, {
                key: chatWorkflow.id,
                "on-click": 
                        _ctx.goWorkflow.bind(
                            this,
                            Number(chatWorkflow.id),
                            chatWorkflow.name
                        )
                    ,
                label: chatWorkflow.name
              }, null, 8, ["on-click", "label"]))
            }), 128))
          ])
        ]))
  ]))
}