<template>
    <InsightsDetailRow
        :show-chevron="showChevron"
        :wide-layout="wideLayout"
        :selected="selected"
        :on-sidebar="onSidebar"
    >
        <template #icon>
            <slot name="icon" />
        </template>
        <template #title>
            <Typography variant="subtitle1">{{ title }}</Typography>
        </template>
        <template #subtitle>
            <Typography variant="caption" class="subtitle">{{
                description
            }}</Typography>
            <slot name="badge" />
        </template>
        <template #score>
            <div class="score-container">
                <Typography variant="subtitle1">
                    {{
                        formatScore(
                            score,
                            false,
                            false,
                            filterQuestionType,
                            $companyVars?.decimals
                        )
                    }}
                </Typography>
                <Typography variant="caption">
                    <UpDownArrow :positive="deltaPositive" />
                </Typography>
            </div>
            <Typography v-if="percentage" variant="caption" class="percent">
                {{ previousPercentage }}
                <FontAwesomeIcon
                    :icon="faArrowRight"
                    :class="{
                        red: !isPercentageChangePositive,
                        green: isPercentageChangePositive,
                    }"
                />
                <span class="off-black"> {{ percentage }}</span>
            </Typography>
        </template>
    </InsightsDetailRow>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import InsightsDetailRow from '@/mobile/src/components/Insights/InsightsDetailRow.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import InfoIcon from '@/assets/img/Icons/info.svg'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import { faArrowRight } from 'fontawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { Getter } from 'vuex-facing-decorator'
import { formatScore } from '@/utils/number'

@Component({
    methods: { formatScore },
    components: {
        AvatarOrInitials,
        UpDownArrow,
        Typography,
        InsightsDetailRow,
        FontAwesomeIcon,
    },
})
export default class InsightsEngagementListItem extends Vue {
    faArrowRight = faArrowRight
    readonly infoIcon = InfoIcon
    showScore = true

    @Prop({ type: String, required: true })
    public readonly title!: string

    @Prop({ type: String, required: true })
    public readonly description!: string

    @Prop({ type: String, required: false, default: null })
    public readonly badge!: string

    @Prop({ type: Number, required: true })
    public readonly score!: number

    @Prop({ type: String, required: true })
    public readonly previousPercentage!: string

    @Prop({ type: String, required: true })
    public readonly percentage!: string

    @Prop({ type: Boolean, required: true })
    public readonly deltaPositive!: boolean

    @Prop({ required: false, default: false })
    wideLayout

    @Prop({ required: false, default: false })
    showChevron

    @Prop({ required: false, default: false })
    selected

    @Prop({ type: Boolean, required: false, default: true })
    public readonly onSidebar!: boolean

    @Getter public filterQuestionType
    @Getter public $companyVars

    public get isPercentageChangePositive() {
        return this.percentage > this.previousPercentage
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.subtitle {
    color: @grey;
    margin-top: 2px;
}

.score-container {
    display: flex;

    .subtitle1 {
        margin-left: auto;
    }

    .caption {
        margin-top: 4px;
    }
}

.percent {
    margin-top: 2px;
    text-align: right;
    color: @grey;

    .red {
        color: @errorRed;
    }

    .green {
        color: @successGreen;
    }

    .off-black {
        color: @offBlack;
    }
}

.info-icon {
    position: relative;
    top: 12px;
}
</style>
