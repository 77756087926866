<template>
    <div class="brief-info" :class="{ 'has-rank': showRank }">
        <!--            avatar or title and ranking -->
        <div class="avatar-title">
            <div v-if="tsCurrentGroupAvatar" class="avatar">
                <img :src="avatarUrl" alt="avatar" class="avatar-img" />
            </div>
            <div>
                <!--        TODO show avatar -->
                <div class="group-name">
                    <TextClamp :text="tsCurrentGroupTitle" :max-lines="1" />
                </div>

                <div v-if="tsNpsSummary.rank" class="title-rank">
                    {{ tsNpsSummary.rank
                    }}{{ getOrdinalRank(tsNpsSummary.rank) }} within
                    {{ tsNpsSummary.rankFieldLabel }}
                </div>
            </div>
        </div>
        <!--            response count and nps and change -->
        <div class="response-nps">
            <div class="response">
                <div class="label">
                    TOTAL RESPONSES
                    <!-- display tooltip only at root level-->
                    <NPSTooltip
                        v-if="
                            !isMobile && !previousTeamScoreboard && !hideTooltip
                        "
                        :width="240"
                        color="#93A1BE"
                    >
                        <div class="total-response-tooltip-text">
                            <p>
                                Team Scoreboard only shows surveys which measure
                                frontline performance with a Scorecard question.
                                As a result it may be different to your
                                AskNicely dashboard which measures performance
                                from all your surveys.
                            </p>
                        </div>
                    </NPSTooltip>
                </div>
                <div class="number">
                    <FormattedNumber :number="tsNpsSummary.total" />
                </div>
            </div>
            <div class="nps-block">
                <div class="nps">
                    {{ getNpsRounded(tsNpsSummary.nps)
                    }}{{ csatInPercentage ? '%' : '' }}
                </div>
                <div v-if="!tsNpsSummary.isFiveScore" class="change">
                    <img
                        v-if="tsNpsSummary.change >= 0"
                        src="@/assets/img/Icons/leaderboard-up.svg"
                        alt=""
                    />
                    <img
                        v-else
                        src="@/assets/img/Icons/leaderboard-down.svg"
                        alt=""
                    />
                    <span class="number">{{
                        getNpsRounded(tsNpsSummary.change)
                    }}</span>
                </div>
            </div>
        </div>
        <Loading v-if="!!tsLoadingNpsSummary" loading />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { formatScore, getOrdinal } from '@/utils/number'

import Tweezer from 'tweezer.js'
import {
    TeamScoreboardEntity,
    TsNpsSummaryEntity,
} from '@/entities/teamscoreboard'
import FormattedNumber from '@/components/FormattedNumber.vue'
import Loading from '@/components/Loading.vue'
import NPSTooltip from '@/components/NPSTooltip.vue'
import { getCDNUrl } from '@/utils/cdn'
import TextClamp from '@/components/TextClamp.vue'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'

@Component({
    components: { NPSTooltip, Loading, FormattedNumber, TextClamp },
})
export default class TsNpsInfo extends Vue {
    @Prop({ type: Boolean, default: false })
    isMobile
    @Prop({ type: Boolean, default: false })
    hideTooltip
    @Getter public tsNpsSummary?: TsNpsSummaryEntity
    @Getter public teamScoreboard
    @Getter public tsFiltersHasGroup
    @Getter public previousTeamScoreboard?: TeamScoreboardEntity
    @Getter public tsCurrentGroupTitle
    @Getter public tsCurrentGroupAvatar
    @Getter public tsLoadingNpsSummary
    @Getter public filterQuestionType
    @Getter public $companyVars

    private csatInPercentage = false

    public get avatarUrl() {
        return getCDNUrl(this.tsCurrentGroupAvatar)
    }

    public getOrdinalRank(rank: string | number) {
        return getOrdinal(rank)
    }

    public lastValue = 0

    public getNpsRounded(val: number) {
        // Round 100.0 to 100 and -100.0 to -100
        if (val === 100 || val === -100) {
            return val.toFixed(0)
        }

        return formatScore(
            val,
            isFiveScoreQuestionType(this.filterQuestionType),
            this.csatInPercentage,
            this.filterQuestionType,
            this.$companyVars?.decimals
        )
    }

    @Watch('tsNpsSummary')
    public async onTsNpsSummaryChanged() {
        this.csatInPercentage =
            this.$companyVars.has_display_csat_as_percentage == '1' &&
            this.filterQuestionType === 'csat'
        new Tweezer({
            start: this.lastValue,
            end: this.tsNpsSummary ? this.tsNpsSummary.nps : 0,
            duration: 1000,
        })
            .on('tick', (value) => {
                if (this.tsNpsSummary) {
                    this.tsNpsSummary.nps = value
                } else {
                    this.tsNpsSummary = {
                        nps: value,
                        change: 0,
                        total: 0,
                        promoter: 0,
                        passive: 0,
                        detractor: 0,
                        scorecardQuestionTotal: 0,
                    }
                }
            })
            .on('done', () => {
                if (this.tsNpsSummary) {
                    this.lastValue = this.tsNpsSummary.nps
                }
            })
            .begin()
    }

    public get showRank(): boolean {
        // If we have more than one sidebarFilter
        if (this.tsFiltersHasGroup) {
            return false
        }

        if (!this.tsNpsSummary || !this.tsNpsSummary.rank) {
            return false
        }

        return true
    }
}
</script>

<style scoped lang="less">
@import '../../styles/palette.less';
@import '../../styles/typography.less';

.brief-info {
    position: relative;
    padding: 15px;

    .avatar-title {
        display: flex;
    }

    .avatar-img {
        width: 24px;
        border-radius: 24px;
        margin-right: 8px;
    }

    .company-name {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
    }

    .group-name {
        font-weight: 500;
        font-size: 18px;
    }

    .title-rank {
    }

    .response-nps {
        display: flex;
        justify-content: space-between;

        .response {
            padding-top: 20px;

            .total-response-tooltip-text {
                margin: 4px 0;
            }

            .missing-responses-link {
                text-decoration: underline !important;
            }

            .label {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;

                color: @grey40;
            }

            .number {
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 1px;
                margin-top: 5px;
            }
        }

        .nps-block {
            text-align: right;

            .nps {
                font-weight: 500;
                font-size: 38px;
                line-height: 38px;
                color: @blue;
                margin-top: 5px;
            }

            .change {
                color: @fontColor;

                .number {
                    font-size: 14px;
                    color: @fontColor;
                    margin-left: 8px;
                }
            }
        }
    }
}
</style>
