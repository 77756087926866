import * as amplitude from '@amplitude/analytics-browser'
const { amplitudeApiKey } = require('@/mobile/src/utils/amplitude.ts')
export function initializeAmplitude(userEmail: string): void {
    try {
        amplitude.init(amplitudeApiKey, userEmail, {
            fetchRemoteConfig: true,
            autocapture: true,
        })
    } catch (error: unknown) {
        console.error(
            'Failed to initialize Amplitude. Error:',
            error instanceof Error ? error.message : String(error)
        )
    }
}
