<template>
    <ScorecardSubpage slide="left" :show-header="!embedded" :title="title">
        <div class="trend-container">
            <div class="panel">
                <div class="title">{{ ratingLabel }} last 30 days</div>
                <ScorecardGraph :team-member-user-id="teamMemberUserId" />
            </div>
        </div>
        <Loading :loading="loading" />
    </ScorecardSubpage>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { ScorecardEntity } from '@/entities/scorecard'
import ScorecardSubpage from '@/pages/scorecard/components/ScorecardSubpage.vue'
import { embedded } from '@/utils/react-native-webview'
import ScorecardGraph from '@/components/Scorecard/ScorecardGraph.vue'
import IconChangeUp from '@/assets/img/Icons/leaderboard-up.svg'
import IconChangeDown from '@/assets/img/Icons/leaderboard-down.svg'
import { formatScore } from '@/utils/number'

@Component({
    methods: { formatScore },
    components: { ScorecardGraph, ScorecardSubpage, Loading },
})
export default class ScorecardTrend extends Vue {
    @Getter public slide!: string
    @Getter public scorecard!: ScorecardEntity
    @Getter public scorecardLoading!: boolean
    @Getter public $companyVars

    @Prop({ type: String, default: null })
    public teamMemberUserId!: string // view another users trend

    public get loading() {
        return this.scorecardLoading
    }

    public get ratingLabel() {
        return this.scorecard.isFiveScoreQuestionType ? 'Rating' : 'NPS'
    }

    public get nps() {
        if (!this.scorecard) {
            return 'loading...'
        }
        return this.scorecard.nps
    }

    public get title() {
        if (!this.scorecard) {
            return 'loading...'
        }
        const iconSrc =
            this.scorecard.change >= 0 ? IconChangeUp : IconChangeDown

        return `${this.ratingLabel} ${formatScore(this.nps, false, false, this.scorecard.questionType, this.$companyVars?.decimals)}
                    <img class="trend-caret" src="${iconSrc}">
                            <small style="font-size: 12px;">${formatScore(this.scorecard.change, false, false, this.scorecard.questionType, this.$companyVars?.decimals)}</small>`
    }

    public get embedded() {
        return embedded
    }
}
</script>

<style scoped lang="less">
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.trend-container {
    .panel {
        background: #ffffff;
        border: 1px solid #e0e7ff;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 12px 12px 0 12px;
        padding: 12px;

        .title {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 11px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #93a1be;
        }
    }
}
</style>
<style lang="less">
.trend-caret {
    width: 8px;
    margin-left: 4px;
    position: relative;
    top: 1px;
}
</style>
