import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/Icons/Grey/Chevron Down.svg'
import _imports_1 from '@/assets/img/Icons/Grey/Chevron Down.svg'
import _imports_2 from '@/assets/img/Icons/Grey/Chevron Down.svg'


const _withScopeId = n => (_pushScopeId("data-v-ec7a717a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left-container" }
const _hoisted_2 = { class: "avatar" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "middle-container" }
const _hoisted_5 = { class: "trending-topic-responses-mentions-num" }
const _hoisted_6 = { class: "trending-topic-responses-mentions-rate" }
const _hoisted_7 = { class: "right-container" }
const _hoisted_8 = { class: "trending-topic-nps-change" }
const _hoisted_9 = { class: "trending-topic-nps-score" }
const _hoisted_10 = { class: "left-container" }
const _hoisted_11 = { class: "avatar" }
const _hoisted_12 = { class: "left-title" }
const _hoisted_13 = { class: "middle-container details" }
const _hoisted_14 = { class: "sub-header coaching-scorecard-container" }
const _hoisted_15 = {
  key: 0,
  class: "coaching-scorecard-info"
}
const _hoisted_16 = { class: "coaching-scorecard-topic-row" }
const _hoisted_17 = { class: "coaching-scorecard-topic-score" }
const _hoisted_18 = {
  key: 1,
  class: "coaching-scorecard-info"
}
const _hoisted_19 = { class: "coaching-scorecard-topic-row" }
const _hoisted_20 = { class: "coaching-scorecard-topic-score" }
const _hoisted_21 = { class: "right-container" }
const _hoisted_22 = { class: "trending-topic-nps-change" }
const _hoisted_23 = { class: "left-container" }
const _hoisted_24 = { class: "avatar" }
const _hoisted_25 = { class: "left-title" }
const _hoisted_26 = { class: "middle-container details" }
const _hoisted_27 = { class: "sub-header coaching-scorecard-container" }
const _hoisted_28 = { class: "coaching-scorecard-info" }
const _hoisted_29 = { class: "coaching-scorecard-topic-row" }
const _hoisted_30 = { class: "coaching-scorecard-topic-score" }
const _hoisted_31 = {
  key: 0,
  class: "coaching-scorecard-info"
}
const _hoisted_32 = { class: "coaching-scorecard-topic-row" }
const _hoisted_33 = { class: "coaching-scorecard-topic-score" }
const _hoisted_34 = { class: "right-container" }
const _hoisted_35 = { class: "trending-topic-nps-change" }
const _hoisted_36 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_UpDownArrow = _resolveComponent("UpDownArrow")!
  const _component_AvatarOrInitials = _resolveComponent("AvatarOrInitials")!
  const _component_BestWorstTopicTag = _resolveComponent("BestWorstTopicTag")!
  const _component_TopicDetail = _resolveComponent("TopicDetail")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(['coaching-list-item', 'desktop', { selected: _ctx.selected }]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToCoachingDetailedPage && _ctx.goToCoachingDetailedPage(...args)))
    }, [
      (_ctx.coachingMetricType === 'trending_topic')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.data.badge)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      width: 44,
                      height: 44,
                      src: _ctx.getBadgeSchema(_ctx.data.badge).img,
                      alt: _ctx.getBadgeSchema(_ctx.data.badge).label
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_Typography, {
                variant: "subtitle1",
                class: "left-title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ], undefined, true),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mentions), 1),
                _createTextVNode(" mentions ")
              ]),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.responseRate) + "% of all responses ", 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.formatScore(
                                _ctx.data.score_change,
                                false,
                                false,
                                _ctx.filterQuestionType,
                                _ctx.$companyVars?.decimals
                            )) + " ", 1),
                (_ctx.data.score_change !== 0)
                  ? (_openBlock(), _createBlock(_component_UpDownArrow, {
                      key: 0,
                      positive: _ctx.data.score_change > 0,
                      class: "change"
                    }, null, 8, ["positive"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatScore(
                                _ctx.score,
                                false,
                                false,
                                _ctx.filterQuestionType,
                                _ctx.$companyVars?.decimals
                            )) + " " + _toDisplayString(_ctx.scoreLabel), 1)
            ]),
            _createElementVNode("img", {
              src: _imports_0,
              class: _normalizeClass(['chevron', { 'detail-open': _ctx.showDetail }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChangeDetail && _ctx.onChangeDetail(...args)))
            }, null, 2)
          ], 64))
        : (_ctx.coachingMetricType === 'top_performer')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_AvatarOrInitials, {
                    user: _ctx.data.user,
                    size: 44
                  }, null, 8, ["user"])
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.data.user.name), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  (_ctx.highestScorecardScore.title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_BestWorstTopicTag, {
                            positive: true,
                            title: _ctx.highestScorecardScore.title
                          }, null, 8, ["title"]),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.formatScore(
                                            _ctx.score,
                                            false,
                                            false,
                                            _ctx.filterQuestionType,
                                            _ctx.$companyVars?.decimals
                                        )) + " " + _toDisplayString(_ctx.scoreLabel), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (
                                _ctx.lowestScorecardScore.title &&
                                _ctx.highestScorecardScore !== _ctx.lowestScorecardScore
                            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_BestWorstTopicTag, {
                            positive: false,
                            title: _ctx.lowestScorecardScore.title
                          }, null, 8, ["title"]),
                          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.formatScore(
                                            _ctx.score,
                                            false,
                                            false,
                                            _ctx.filterQuestionType,
                                            _ctx.$companyVars?.decimals
                                        )) + " " + _toDisplayString(_ctx.scoreLabel), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createTextVNode(_toDisplayString(_ctx.formatScore(
                                _ctx.score,
                                false,
                                false,
                                _ctx.filterQuestionType,
                                _ctx.$companyVars?.decimals
                            )) + " ", 1),
                  (_ctx.data.score_change !== 0)
                    ? (_openBlock(), _createBlock(_component_UpDownArrow, {
                        key: 0,
                        positive: _ctx.data.score_change > 0,
                        class: "change"
                      }, null, 8, ["positive"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("img", {
                src: _imports_1,
                class: _normalizeClass([
                        'chevron',
                        'chevron-sideways',
                        { 'member-selected': _ctx.isTeamMemberSelected },
                    ])
              }, null, 2)
            ], 64))
          : (_ctx.coachingMetricType === 'biggest_change')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_AvatarOrInitials, {
                      user: _ctx.data.user,
                      size: 44
                    }, null, 8, ["user"])
                  ]),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.data.user.name), 1)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.formatScore(
                                            _ctx.data.score_current,
                                            false,
                                            false,
                                            _ctx.filterQuestionType,
                                            _ctx.$companyVars?.decimals
                                        )) + " " + _toDisplayString(_ctx.scoreLabel), 1)
                      ])
                    ]),
                    (_ctx.biggestScorecardChange.title)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_component_BestWorstTopicTag, {
                              positive: 
                                        _ctx.biggestScorecardChange.score_change > 0
                                    ,
                              title: _ctx.biggestScorecardChange.title
                            }, null, 8, ["positive", "title"]),
                            _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.biggestScorecardChange.score_change > 0
                                            ? '+'
                                            : '-') + " " + _toDisplayString(Math.abs(
                                            _ctx.biggestScorecardChange.score_change
                                        ).toFixed(1)), 1),
                            _createVNode(_component_UpDownArrow, {
                              positive: 
                                        _ctx.biggestScorecardChange.score_change > 0
                                    ,
                              class: "change-small"
                            }, null, 8, ["positive"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createTextVNode(_toDisplayString(_ctx.formatScore(
                                _ctx.data.score_change,
                                false,
                                false,
                                _ctx.filterQuestionType,
                                _ctx.$companyVars?.decimals
                            )) + " ", 1),
                    (_ctx.data.score_change !== 0)
                      ? (_openBlock(), _createBlock(_component_UpDownArrow, {
                          key: 0,
                          positive: _ctx.data.score_change > 0,
                          class: "change"
                        }, null, 8, ["positive"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("img", {
                  src: _imports_2,
                  class: _normalizeClass([
                        'chevron',
                        'chevron-sideways',
                        { 'member-selected': _ctx.isTeamMemberSelected },
                    ])
                }, null, 2)
              ], 64))
            : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, { name: "fade-height" }, {
      default: _withCtx(() => [
        (_ctx.showDetail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createVNode(_component_TopicDetail, { topic: _ctx.title }, null, 8, ["topic"])
            ]))
          : _createCommentVNode("", true)
      ], undefined, true),
      _: 1
    })
  ]))
}