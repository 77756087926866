<template>
    <div>
        <div
            :class="['coaching-list-item', 'desktop', { selected }]"
            @click="goToCoachingDetailedPage"
        >
            <!-- Trending Topic -->
            <template v-if="coachingMetricType === 'trending_topic'">
                <div class="left-container">
                    <div class="avatar">
                        <img
                            v-if="data.badge"
                            :width="44"
                            :height="44"
                            :src="getBadgeSchema(data.badge).img"
                            :alt="getBadgeSchema(data.badge).label"
                        />
                    </div>
                    <Typography variant="subtitle1" class="left-title">{{
                        title
                    }}</Typography>
                </div>
                <div class="middle-container">
                    <div>
                        <span class="trending-topic-responses-mentions-num">{{
                            mentions
                        }}</span>
                        mentions
                    </div>
                    <div class="trending-topic-responses-mentions-rate">
                        {{ responseRate }}% of all responses
                    </div>
                </div>
                <div class="right-container">
                    <div class="trending-topic-nps-change">
                        {{
                            formatScore(
                                data.score_change,
                                false,
                                false,
                                filterQuestionType,
                                $companyVars?.decimals
                            )
                        }}
                        <UpDownArrow
                            v-if="data.score_change !== 0"
                            :positive="data.score_change > 0"
                            class="change"
                        />
                    </div>
                    <div class="trending-topic-nps-score">
                        {{
                            formatScore(
                                score,
                                false,
                                false,
                                filterQuestionType,
                                $companyVars?.decimals
                            )
                        }}
                        {{ scoreLabel }}
                    </div>
                </div>
                <img
                    src="@/assets/img/Icons/Grey/Chevron Down.svg"
                    :class="['chevron', { 'detail-open': showDetail }]"
                    @click="onChangeDetail"
                />
            </template>
            <!-- Duplicate rows to make it clear for now, can be extracted into another component later -->
            <template v-else-if="coachingMetricType === 'top_performer'">
                <div class="left-container">
                    <div class="avatar">
                        <AvatarOrInitials :user="data.user" :size="44" />
                    </div>
                    <div class="left-title">{{ data.user.name }}</div>
                </div>
                <div class="middle-container details">
                    <div class="sub-header coaching-scorecard-container">
                        <div
                            v-if="highestScorecardScore.title"
                            class="coaching-scorecard-info"
                        >
                            <div class="coaching-scorecard-topic-row">
                                <BestWorstTopicTag
                                    :positive="true"
                                    :title="highestScorecardScore.title"
                                />
                                <span class="coaching-scorecard-topic-score">{{
                                        formatScore(
                                            score,
                                            false,
                                            false,
                                            filterQuestionType,
                                            $companyVars?.decimals
                                        )
                                    }}
                                    {{ scoreLabel }}</span>
                            </div>
                        </div>
                        <div
                            v-if="
                                lowestScorecardScore.title &&
                                highestScorecardScore !== lowestScorecardScore
                            "
                            class="coaching-scorecard-info"
                        >
                            <div class="coaching-scorecard-topic-row">
                                <BestWorstTopicTag
                                    :positive="false"
                                    :title="lowestScorecardScore.title"
                                />
                                <span class="coaching-scorecard-topic-score">{{
                                        formatScore(
                                            score,
                                            false,
                                            false,
                                            filterQuestionType,
                                            $companyVars?.decimals
                                        )
                                    }}
                                    {{ scoreLabel }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-container">
                    <div class="trending-topic-nps-change">
                        {{
                            formatScore(
                                score,
                                false,
                                false,
                                filterQuestionType,
                                $companyVars?.decimals
                            )
                        }}
                        <UpDownArrow
                            v-if="data.score_change !== 0"
                            :positive="data.score_change > 0"
                            class="change"
                        />
                    </div>
                </div>
                <img
                    src="@/assets/img/Icons/Grey/Chevron Down.svg"
                    :class="[
                        'chevron',
                        'chevron-sideways',
                        { 'member-selected': isTeamMemberSelected },
                    ]"
                />
            </template>
            <template v-else-if="coachingMetricType === 'biggest_change'">
                <div class="left-container">
                    <div class="avatar">
                        <AvatarOrInitials :user="data.user" :size="44" />
                    </div>
                    <div class="left-title">{{ data.user.name }}</div>
                </div>
                <div class="middle-container details">
                    <div class="sub-header coaching-scorecard-container">
                        <div class="coaching-scorecard-info">
                            <div class="coaching-scorecard-topic-row">
                                <span class="coaching-scorecard-topic-score">{{
                                        formatScore(
                                            data.score_current,
                                            false,
                                            false,
                                            filterQuestionType,
                                            $companyVars?.decimals
                                        )
                                    }}
                                    {{ scoreLabel }}</span>
                            </div>
                        </div>
                        <div
                            v-if="biggestScorecardChange.title"
                            class="coaching-scorecard-info"
                        >
                            <div class="coaching-scorecard-topic-row">
                                <BestWorstTopicTag
                                    :positive="
                                        biggestScorecardChange.score_change > 0
                                    "
                                    :title="biggestScorecardChange.title"
                                />
                                <div class="coaching-scorecard-topic-score">
                                    {{
                                        biggestScorecardChange.score_change > 0
                                            ? '+'
                                            : '-'
                                    }}
                                    {{
                                        Math.abs(
                                            biggestScorecardChange.score_change
                                        ).toFixed(1)
                                    }}
                                </div>
                                <UpDownArrow
                                    :positive="
                                        biggestScorecardChange.score_change > 0
                                    "
                                    class="change-small"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-container">
                    <div class="trending-topic-nps-change">
                        {{
                            formatScore(
                                data.score_change,
                                false,
                                false,
                                filterQuestionType,
                                $companyVars?.decimals
                            )
                        }}
                        <UpDownArrow
                            v-if="data.score_change !== 0"
                            :positive="data.score_change > 0"
                            class="change"
                        />
                    </div>
                </div>
                <img
                    src="@/assets/img/Icons/Grey/Chevron Down.svg"
                    :class="[
                        'chevron',
                        'chevron-sideways',
                        { 'member-selected': isTeamMemberSelected },
                    ]"
                />
            </template>
        </div>
        <transition name="fade-height">
            <div v-if="showDetail">
                <TopicDetail :topic="title" />
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import InsightsMetric from '@/mobile/src/components/Insights/InsightsMetric.vue'
import UpDownArrow from '@/components/UpDownArrow.vue'
import BestWorstTopicTag from '@/mobile/src/components/BestWorstTopicTag.vue'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import TopicDetail from '@/pages/insights/TopicDetail.vue'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { Action, Getter } from 'vuex-facing-decorator'
import { CoachingInsightItem, CoachingMetricType } from '@/entities/insights'
import { ILeaderboard, QuestionType } from '@/entities'
import { badgeSchemas } from '@/entities/scorecard'
import { formatScore } from '@/utils/number'

@Component({
    methods: { formatScore },
    components: {
        TopicDetail,
        AvatarOrInitials,
        BestWorstTopicTag,
        UpDownArrow,
        InsightsMetric,
        Typography,
    },
    emits: ['change-detail'],
})
export default class InsightsCoachingListItemWeb extends Vue {
    @Prop({ type: String, default: null })
    private activeDetail!: string

    @Getter
    readonly selectedTeamMemberId!: null | number

    @Getter public filterQuestionType
    @Getter public $companyVars

    private route = useRoute()

    constructor(opts) {
        super(opts, Vue)
    }

    get showDetail() {
        return this.activeDetail === this.title
    }

    get isTeamMemberSelected() {
        return this.data.user.id === this.selectedTeamMemberId
    }

    get selected() {
        if (this.coachingMetricType === 'trending_topic') {
            return this.route.params.topic === this.title
        }
        return this.isTeamMemberSelected
    }

    public onChangeDetail() {
        this.$emit(
            'change-detail',
            this.activeDetail === this.title ? '' : this.title
        )
    }

    // InsightsCoachingListItem.vue
    @Prop({ type: String, default: null })
    protected coachingMetricType!: CoachingMetricType

    @Prop({ type: String, default: null })
    protected title!: string

    @Prop({ type: Object, default: null })
    protected data!: CoachingInsightItem

    @Prop({ type: Number, default: null })
    private totalCount!: number

    @Prop({ type: String, default: {} })
    private questionType!: QuestionType

    private router = useRouter()

    get score() {
        if (this.coachingMetricType === 'top_performer') {
            return this.data.score_current.toFixed(1)
        } else {
            return this.data.score_change.toFixed(1)
        }
    }

    get mentions() {
        return this.data.total_count
    }

    get responseRate() {
        return ((this.data.total_count / this.totalCount) * 100).toFixed(0)
    }

    get scoreLabel() {
        if (this.questionType === 'nps') {
            return 'NPS'
        }
        return ''
    }

    get biggestScorecardChange() {
        let biggestChange!: CoachingInsightItem
        if (this.data.scorecard) {
            for (const title in this.data.scorecard) {
                if (
                    !biggestChange ||
                    Math.abs(this.data.scorecard[title].score_change) >
                        Math.abs(biggestChange.score_change)
                ) {
                    biggestChange = this.data.scorecard[title]
                    biggestChange.title = title
                }
            }
        }
        return (
            biggestChange ?? {
                title: '',
                score_change: 0,
            }
        )
    }

    get highestScorecardScore() {
        let highestScore!: CoachingInsightItem
        if (this.data.scorecard) {
            for (const title in this.data.scorecard) {
                if (
                    !highestScore ||
                    this.data.scorecard[title].score_current >
                        highestScore.score_current
                ) {
                    highestScore = this.data.scorecard[title]
                    highestScore.title = title
                }
            }
        }
        return (
            highestScore ?? {
                title: '',
                score_current: 0,
            }
        )
    }

    get lowestScorecardScore() {
        let lowestScore!: CoachingInsightItem
        if (this.data.scorecard) {
            for (const title in this.data.scorecard) {
                if (
                    !lowestScore ||
                    this.data.scorecard[title].score_current <
                        lowestScore.score_current
                ) {
                    lowestScore = this.data.scorecard[title]
                    lowestScore.title = title
                }
            }
        }
        return (
            lowestScore ?? {
                title: '',
                score_current: 0,
            }
        )
    }

    public getBadgeSchema(badgeId) {
        return badgeSchemas[badgeId] ?? null
    }

    public goToCoachingDetailedPage() {
        if (this.coachingMetricType === 'trending_topic') {
            this.router
                .push({
                    name: 'InsightsCoachingTopic',
                    params: { topic: this.title },
                })
                .catch((e) => e)
        } else {
            // we can set user.id to 0 if we can't find an actual user mapped
            // possibly if frontline_staff definition mapping in data management was done incorrectly
            if (this.data.user.id) {
                this.router
                    .push({
                        name: 'scorecardHomeUser',
                        params: {
                            teamMemberUserId: this.data.user.id.toString(),
                        },
                    })
                    .catch((e) => e)
            }
            //For Coaching Top Performer and Most Improved list, when the list is too long,
            //we scroll back up to show selected the member information
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/typography';
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';

.coaching-list-item {
    display: flex;
    align-items: center;

    border: 3px solid transparent;
    border-radius: @borderRadius-base;
    padding: 14px;
    cursor: pointer;

    &.selected {
        border: 2px solid @primaryBlue;
        border-radius: 36px;
        padding: 15px;
    }

    .score-container {
        .score {
            display: flex;
            text-align: right;

            .change {
                height: 10px;
                width: 10px;
                margin: auto auto auto 6px;
            }
        }
    }

    .left-container {
        display: flex;
        flex: 3;

        .left-title {
            margin-left: @gutterSpacing-md;
            display: flex;
            align-items: center;
        }
    }

    .middle-container {
        flex: 2;

        &.details {
            align-items: center;
        }

        .coaching-scorecard-topic-row {
            display: flex;
            align-items: center;
            margin-top: 4px;

            &.invisible {
                visibility: hidden;
            }

            .coaching-scorecard-topic-score {
                color: @grey;
                font-size: @fontSize-sm;
                margin-left: @gutterSpacing-base;
                margin-right: 4px;
            }
        }

        .trending-topic-responses-mentions-num {
            font-size: @fontSize-md;
            font-weight: @fontWeight-medium;
        }

        .trending-topic-responses-mentions-rate {
            margin-top: 4px;
            color: @grey;
            font-size: @fontSize-sm;
        }
    }

    .right-container {
        flex: 1;

        .trending-topic-nps-change {
            margin-top: 4px;
            font-size: @fontSize-md;
            font-weight: @fontWeight-medium;
        }

        .trending-topic-nps-score {
            margin-top: 4px;
            color: @grey;
            font-size: @fontSize-sm;

            &.invisible {
                visibility: hidden;
            }
        }
    }

    .chevron {
        flex: 0.25;

        &.chevron-sideways {
            transform: rotate(270deg);
        }

        &.member-selected {
            filter: @blueFilter;
        }
    }

    .detail-open {
        transform: rotate(180deg);
        filter: @blueFilter;
    }
}

.fade-height-enter-active,
.fade-height-leave-active {
    transition: all 0.2s;
    max-height: 5000px; // This is a cheat to allow for the animation to actually work.
}
.fade-height-enter-from,
.fade-height-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
